import { useMutation, useApolloClient } from '@apollo/client';
import { PublishingUpdateSongWriterDocument } from 'src/data/mutations/updateSongWriter/__generated__/updateSongWriter';
import type { PublishingUpdateSongWriterMutation } from 'src/data/mutations/updateSongWriter/__generated__/updateSongWriter';
import type { MutationSuccessCB } from 'src/types';

export const useUpdateSongWriter = (
    onCreateSongWriterCB: MutationSuccessCB
) => {
    const client = useApolloClient();

    const [mutation] = useMutation(PublishingUpdateSongWriterDocument, {
        update() {
            // eslint-disable-next-line @typescript-eslint/no-floating-promises
            client.resetStore();
        },
        onCompleted: (data: PublishingUpdateSongWriterMutation) =>
            onCreateSongWriterCB(data),
        onError: data => {
            const errors = data.graphQLErrors
                ?.map(e =>
                    e.extensions
                        ? Object.keys(e.extensions.validationErrors)
                        : ''
                )
                ?.filter(i => i);
            onCreateSongWriterCB(null, errors.flat());
        },
    });
    return mutation;
};
