import { OrchardLabelSearchDocument } from 'src/data/queries/orchardLabelSearch/__generated__/orchardLabelSearch';
import type {
    OrchardLabelSearchQuery,
    OrchardLabelSearchQueryVariables as Variables,
} from 'src/data/queries/orchardLabelSearch/__generated__/orchardLabelSearch';
import type { ApolloClientObj } from 'src/types';

type OrchardLabelSearch = OrchardLabelSearchQuery['orchardLabelSearch'];

export const getPromisedLabelSearch = async (
    term: string,
    client: ApolloClientObj
): Promise<OrchardLabelSearch> => {
    const { data } = await client.query<OrchardLabelSearchQuery, Variables>({
        query: OrchardLabelSearchDocument,
        variables: { term },
    });

    return data?.orchardLabelSearch;
};
