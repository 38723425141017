import React, { useCallback } from 'react';
import { formatMessage } from '@theorchard/suite-frontend';
import { WarningGlyph } from '@theorchard/suite-icons';
import SongWriterDropdown from 'src/pages/songWriterListPage/songWriterDropdown';
import { CLASSNAME } from 'src/pages/songWriterListPage/songWriters';
import type { SongWriter } from 'src/types';

interface Props {
    category: string;
    songWriter: SongWriter;
    onSongWriterView: (songWriter: SongWriter) => void;
    onSongWriterEdit: (songWriter: SongWriter) => void;
    onSongWriterDelete: (songWriter: SongWriter) => void;
}

const renderNotRegisteredCell = () => (
    <div className={`${CLASSNAME}-not-registered`}>
        <span>{formatMessage('generic.notRegistered')}</span>
        <WarningGlyph />
    </div>
);

const renderControlledCell = ({ agreements, ipi, pro }: SongWriter) => {
    if (agreements.every(({ controlled }) => controlled) && (!ipi || !pro))
        return formatMessage('songWriters.yesNotRegistered');
    if (agreements.every(({ controlled }) => controlled))
        return formatMessage('generic.yes');
    if (agreements.every(({ controlled }) => !controlled))
        return formatMessage('generic.no');

    return formatMessage('generic.both');
};

const SongWriterRow: React.FC<Props> = ({
    category,
    songWriter,
    onSongWriterView,
    onSongWriterEdit,
    onSongWriterDelete,
}) => {
    const onView = useCallback(
        () => onSongWriterView(songWriter),
        [songWriter, onSongWriterView]
    );

    return (
        <tr key={songWriter.id}>
            <td>
                <div className="Songs-link" onClick={onView}>
                    {songWriter.legalName}
                </div>
            </td>
            <td>{songWriter.pro || renderNotRegisteredCell()}</td>
            <td>{songWriter.ipi || renderNotRegisteredCell()}</td>
            <td>{songWriter.compositions.length}</td>
            <td>{renderControlledCell(songWriter)}</td>
            <td>
                <SongWriterDropdown
                    category={category}
                    songWriter={songWriter}
                    onSongWriterView={onSongWriterView}
                    onSongWriterEdit={onSongWriterEdit}
                    onSongWriterDelete={onSongWriterDelete}
                />
            </td>
        </tr>
    );
};

export default SongWriterRow;
