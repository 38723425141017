import { useApolloClient, useMutation } from '@apollo/client';
import deleteComposition from './deleteComposition.gql';

export const useDeleteComposition = () => {
    const client = useApolloClient();

    const [mutation] = useMutation(deleteComposition, {
        update() {
            // eslint-disable-next-line @typescript-eslint/no-floating-promises
            client.resetStore();
        },
    });
    return mutation;
};
