import type { FC } from 'react';
import React, { useCallback, useEffect, useMemo } from 'react';
import { useApolloClient } from '@apollo/client';
import { SearchDropdown } from '@theorchard/suite-components';
import { useIdentity } from '@theorchard/suite-frontend';
import {
    getPromisedLabelSearch,
    useOrchardLabelByIdQuery,
    useOrchardLabels,
} from 'src/data/queries';
import type { OrchardLabelSearchQuery } from 'src/data/queries/orchardLabelSearch/__generated__/orchardLabelSearch';
import type {
    DropdownOption,
    Label,
    SearchableDropdownOption,
} from 'src/types';

type OrchardLabelSearch = OrchardLabelSearchQuery['orchardLabelSearch'][0];

export const CLASSNAME = 'LabelSearchDropdown';

export interface Props {
    disabled?: boolean;
    showAllOption?: boolean;
    onChange: (data?: OrchardLabelSearch) => void;
    vendorId?: number;
}

export const getOptionName = (label: OrchardLabelSearch) =>
    `${label.name} - ${label.uuid}`;

const createOption = (
    label: OrchardLabelSearch
): DropdownOption<OrchardLabelSearch> => ({
    label: getOptionName(label),
    value: label.uuid,
    data: label,
});

export const SHOW_ALL_LABEL_UUID = 'all';
export const SHOW_ALL_LABEL_NAME = 'Show All Labels';
const allOption = {
    label: SHOW_ALL_LABEL_NAME,
    value: SHOW_ALL_LABEL_UUID,
    data: {
        id: { vendorId: 0, subaccountId: 0 },
        name: SHOW_ALL_LABEL_NAME,
        uuid: SHOW_ALL_LABEL_UUID,
    },
};

const LabelSearchDropdown: FC<Props> = ({
    disabled,
    showAllOption = true,
    onChange,
    vendorId,
}) => {
    const identity = useIdentity();
    const apolloClient = useApolloClient();
    const { data: listData } = useOrchardLabels();
    // our vendors won't have subaccountIds so can just set this to 0.
    const { data: selectedLabel } = useOrchardLabelByIdQuery({
        id: { subaccountId: 0, vendorId },
    });

    const inputChangeCallback = async (searchTerm: string) => {
        const data = await getPromisedLabelSearch(searchTerm, apolloClient);

        return data.map(createOption);
    };

    const labelOptions = useMemo<DropdownOption<OrchardLabelSearch>[]>(
        () => [...(listData ?? [])].map(createOption),
        [listData]
    );

    const handleChange = useCallback(
        (newValue?: SearchableDropdownOption<OrchardLabelSearch>) => {
            onChange(newValue?.data);
        },
        [onChange]
    );

    const handleSingleLabel = useCallback(
        (newValue?: Label) => {
            onChange(newValue);
        },
        [onChange]
    );

    useEffect(() => {
        if (listData?.length === 1 && listData[0].id.vendorId !== vendorId)
            handleSingleLabel(listData[0]);
    }, [vendorId, listData, handleSingleLabel]);

    let selectedOption: DropdownOption<OrchardLabelSearch> | null = null;
    if (selectedLabel?.name)
        selectedOption = createOption({
            id: {
                vendorId: selectedLabel.id.vendorId,
                subaccountId: 0,
            },
            name: selectedLabel.name,
            uuid: selectedLabel.uuid,
        });

    const formattedOption = (option: DropdownOption<OrchardLabelSearch>) => {
        const formattedOption =
            identity.isEmployee &&
            option.data?.name !== SHOW_ALL_LABEL_NAME &&
            listData?.length &&
            listData.length > 1
                ? `${option.data?.name} (${option.data?.id.vendorId})`
                : `${option.data?.name}`;
        return (
            <div className={`${CLASSNAME}-option`}>
                <div className={`${CLASSNAME}-option-name`}>
                    {formattedOption}
                </div>
            </div>
        );
    };

    const options: DropdownOption<OrchardLabelSearch>[] = [
        allOption,
        ...(labelOptions || []),
    ];

    if (!showAllOption) options.shift();

    if (listData?.length === 1) {
        const label: Label = listData[0];
        handleSingleLabel(label);
        return <h3 className="singleItem">{label.name}</h3>;
    }

    return (
        <SearchDropdown
            isClearable={false}
            name="labelSearchDropdown"
            className={CLASSNAME}
            onChange={handleChange}
            onLoadOptions={inputChangeCallback}
            defaultOptions={options}
            value={selectedOption || allOption}
            formatOptionLabel={formattedOption}
            debounceTime={300}
            isDisabled={disabled}
        />
    );
};

export default LabelSearchDropdown;
