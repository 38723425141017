import { useRef } from 'react';
import { useLazyQuery } from '@apollo/client';
import { includes } from 'lodash';
import { OwsSearchDocument } from 'src/data/queries/owsSearch/__generated__/owsSearch';
import type { ApolloError } from '@apollo/client';
import type {
    OwsSearchQuery,
    OwsSearchQueryVariables,
} from 'src/data/queries/owsSearch/__generated__/owsSearch';
import type { OwsSearchProducts, OwsSearchTracks } from 'src/types';

interface Variables extends OwsSearchQueryVariables {
    queryId?: number;
}

export type OwsSearchResult = [
    (variables: Variables) => void,
    {
        loading: boolean;
        called: boolean;
        error?: ApolloError;
        songs: OwsSearchTracks;
        products: OwsSearchProducts;
    },
];
export const useLazyOwsSearch = (): OwsSearchResult => {
    const releaseDate = useRef<string | null>(null);
    const queryId = useRef(0);
    const abortController = useRef<AbortController>();
    if (!abortController.current)
        abortController.current = new AbortController();

    const { signal } = abortController.current;

    const [doOwsSearch, owsSearchResult] = useLazyQuery<OwsSearchQuery>(
        OwsSearchDocument,
        { context: { fetchOptions: { signal } } }
    );

    const songsData = owsSearchResult?.data?.trackSearch.tracks || [];
    const productsData =
        owsSearchResult?.data?.allProductsSearch?.products || [];
    let filteredSongsData: OwsSearchTracks = [];
    const filteredProductsData: OwsSearchProducts = [];

    if (releaseDate.current) {
        const now = new Date().getTime();
        filteredSongsData.push(
            ...songsData.filter(
                song =>
                    song.product?.releaseDate &&
                    song.product?.status === 'in_content' &&
                    new Date(song.product?.releaseDate).getTime() > now
            )
        );
    } else filteredSongsData.push(...songsData);

    if (productsData.length)
        filteredProductsData.push(
            ...productsData
                .filter(product => product.contextType === 'digital')
                .map(product => ({
                    ...product,
                    tracks: product.tracks.filter(t => t.isrc),
                }))
        );

    const dedupeSongsByIsrc = (
        trackData: OwsSearchTracks,
        productData: OwsSearchProducts
    ) => {
        const productTracks = [...productData.map(p => p.tracks).flat()];
        const productLsrIds = productTracks.map(
            tr => tr.labelSoundRecording?.id
        );
        return trackData.filter(
            t => !includes(productLsrIds, t.labelSoundRecording?.id)
        );
    };

    if (filteredSongsData.length && filteredProductsData.length)
        filteredSongsData = dedupeSongsByIsrc(
            filteredSongsData,
            filteredProductsData
        );

    return [
        variables => {
            releaseDate.current = variables.releaseDate ?? null;
            abortController.current?.abort();
            abortController.current = new AbortController();
            if (variables.term.length)
                void doOwsSearch({
                    variables: { queryId: queryId.current++, ...variables },
                });
        },
        {
            ...owsSearchResult,
            songs: filteredSongsData,
            products: filteredProductsData,
        },
    ];
};
