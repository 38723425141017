import React, { useState } from 'react';
import {
    Form,
    Field,
    NumberInput,
    Dropdown,
    Sidecar,
} from '@theorchard/suite-components';
import {
    formatMessage,
    Segment,
    useIdentity,
} from '@theorchard/suite-frontend';
import cx from 'classnames';
import PublishersComponent from 'src/components/editSongWriter/publishersComponent';
import { FEATURE_FLAGS, PRO_LIST } from 'src/constants';
import {
    useUpdateSongWriter,
    useUpdateSongWriterMetaData,
} from 'src/data/mutations';
import { useLimitModifyAccessFF } from 'src/utils/features';
import { isValidIpi } from 'src/utils/validators';
import type { ControlledPublisher } from 'src/components/editSongWriter/publishersComponent';
import type { PublishingCreateSongWriterMutation } from 'src/data/mutations/createSongWriter/__generated__/createSongWriter';
import type { SongWriter } from 'src/types';

interface Props {
    isOpen: boolean;
    onClose: (result?: PublishingCreateSongWriterMutation) => void;
    songWriter: SongWriter | null;
}

const EditSongWriter: React.FC<Props> = ({ songWriter, isOpen, onClose }) => {
    const isPublisherEditAllow =
        useIdentity().features[FEATURE_FLAGS.ALLOW_EDIT_SONGWRITER_PUBLISHER];
    const isLimitedModifyAccess = useLimitModifyAccessFF();

    const isControlledMixed =
        songWriter?.agreements?.some(a => a.controlled) &&
        songWriter?.agreements?.some(a => !a.controlled);
    const [name, setName] = useState(songWriter?.legalName);
    const [pro, setPro] = useState(songWriter?.pro);
    const [ipi, setIpi] = useState(songWriter?.ipi);
    const [publishers, setPublishers] = useState<ControlledPublisher[]>(
        (songWriter?.agreements
            .filter(a => a.publisher)
            .map(a => ({
                controlled: a.controlled,
                ...a.publisher,
            })) as ControlledPublisher[]) || []
    );
    const [hasSubmitted, setHasSubmitted] = useState(false);
    const [songWriterValidationErrorKeys, setSongWriterValidationErrorKeys] =
        useState<string[] | null>(null);
    const [hasPublishingCompany, setHasPublishingCompany] = useState<boolean>(
        !!publishers.length
    );
    const [newPublisher, setNewPublisher] =
        useState<ControlledPublisher | null>(null);

    const determineIsControlled = () => {
        if (
            songWriter?.agreements.every(({ controlled }) => controlled) &&
            (!ipi || !pro)
        )
            return true;
        if (songWriter?.agreements.every(({ controlled }) => controlled))
            return true;
        if (songWriter?.agreements.every(({ controlled }) => !controlled))
            return false;
        return null;
    };

    const [isControlled, setIsControlled] = useState<boolean | null>(
        determineIsControlled()
    );

    const reset = () => {
        setHasSubmitted(false);
        setName('');
        setIsControlled(null);
        setIpi('');
        setPro(null);
        setSongWriterValidationErrorKeys(null);
    };

    const closeAndReset = () => {
        onClose();
        reset();
    };
    const onSubmitCB = (
        result: object | null,
        errors: string[] | undefined
    ) => {
        if (result) onClose(result as PublishingCreateSongWriterMutation);
        else if (errors) setSongWriterValidationErrorKeys(errors);
    };
    const updateSongWriter = useUpdateSongWriter(onSubmitCB);
    const updateSongWriterMetaData = useUpdateSongWriterMetaData(onSubmitCB);

    const nameIsValid = /\w \w/.exec(name || '');
    const nameIsInvalid = !nameIsValid;
    const category = formatMessage('songWriterManager.editSongWriter');
    const songWriterIpiIsInvalid = ipi && !isValidIpi(ipi);
    const songWriterIpiIsNotUnique = songWriterValidationErrorKeys?.includes(
        'songWriterIPICollision'
    );

    const onIpiChange = (value: string, setter: (v: string) => void) => {
        setSongWriterValidationErrorKeys(null);
        let formatted = value.slice(-11);
        if (value.length < 11) formatted = `00000000000${value}`.slice(-11);
        setter(formatted);
    };

    const onSave = () => {
        if (isLimitedModifyAccess) return;

        setHasSubmitted(true);
        if (
            nameIsInvalid ||
            songWriterIpiIsInvalid ||
            songWriterValidationErrorKeys
        )
            return;

        void Segment.trackEvent('Click - Edit Songwriter', {
            category,
            songWriterId: songWriter?.id,
            songWriterLegalName: songWriter?.legalName,
            songWriterIpi: songWriter?.ipi,
            songWriterPro: songWriter?.pro,
        });
        const baseVariables = {
            id: songWriter?.id || '',
            name: name || songWriter?.legalName || '',
            ipi: ipi || songWriter?.ipi || '',
            pro: pro || songWriter?.pro || '',
            label: { uuid: songWriter?.vendor?.uuid || '' },
        };

        const updatedPublishers = publishers.map(pub => ({
            controlled: pub.controlled,
            id: pub.id,
            ipi: pub.ipi,
            name: pub.name,
            pro: pub.pro,
            excludedTerritories: [],
        }));
        if (newPublisher)
            updatedPublishers.push({
                controlled: newPublisher.controlled,
                id: newPublisher.id,
                ipi: newPublisher.ipi,
                name: newPublisher.name,
                pro: newPublisher.pro,
                excludedTerritories: [],
            });

        const variables = isPublisherEditAllow
            ? {
                  ...baseVariables,
                  controlled: isControlled,
                  publishers: hasPublishingCompany ? updatedPublishers : [],
              }
            : {
                  ...baseVariables,
                  controlled: isControlled,
                  publishers: [],
              };

        if (isPublisherEditAllow) void updateSongWriter({ variables });
        else void updateSongWriterMetaData({ variables });
    };

    return (
        <Sidecar
            isOpen={isOpen}
            onRequestClose={closeAndReset}
            className={cx(
                'SongWriterManager',
                isLimitedModifyAccess ? 'save-disabled' : ''
            )}
            header={formatMessage('songWriterManager.editSongWriter')}
            cancelTitle={formatMessage('generic.cancel')}
            confirmTitle={formatMessage('generic.save')}
            onConfirm={onSave}
        >
            <Form validated={hasSubmitted}>
                <Field
                    controlId="legal-name"
                    labelText={formatMessage(
                        'songWriterManager.songWriterLegalName'
                    )}
                    isRequired
                    errorMessage={
                        (hasSubmitted &&
                            !nameIsValid &&
                            formatMessage('errors.nameNeedsSpace')) ||
                        undefined
                    }
                >
                    <Form.Control
                        type="text"
                        placeholder={formatMessage(
                            'songWriterManager.songWriterLegalNamePlaceholder'
                        )}
                        value={name}
                        onChange={e => setName(e.target.value)}
                    />
                </Field>
                {!isPublisherEditAllow && (
                    <Field
                        controlId="isControlled"
                        labelText={formatMessage(
                            'songWriterManager.isControlled'
                        )}
                        isRequired
                    >
                        <Form.Check
                            checked={isControlled === true}
                            onChange={() => setIsControlled(true)}
                            name="isControlled"
                            label={formatMessage('generic.yes')}
                            type="radio"
                            id="isControlled1"
                        />
                        <Form.Check
                            checked={isControlled === false}
                            onChange={() => setIsControlled(false)}
                            name="isControlled"
                            label={formatMessage('generic.no')}
                            type="radio"
                            id="isControlled2"
                        />
                        <Form.Check
                            disabled
                            checked={isControlledMixed && isControlled === null}
                            name="isControlled"
                            label={formatMessage('generic.both')}
                            type="radio"
                            id="isControlled3"
                        />
                    </Field>
                )}
                <div>
                    <Field
                        controlId="pro"
                        labelText={formatMessage(
                            'songWriterManager.songwritersPro'
                        )}
                        isRequired={!!isControlled}
                    >
                        <Dropdown
                            options={PRO_LIST.map((p: string) => ({
                                value: p,
                                label: p,
                            }))}
                            placeholder={formatMessage(
                                'songWriterManager.songwriterProPlaceholder'
                            )}
                            selectedValue={pro || undefined}
                            onChange={option => setPro(option?.value)}
                        />
                    </Field>

                    <Field
                        controlId="ipi"
                        labelText={formatMessage(
                            'songWriterManager.songwritersIpi'
                        )}
                        isRequired={!!isControlled}
                        errorMessage={
                            (hasSubmitted &&
                                ((songWriterIpiIsInvalid &&
                                    formatMessage('errors.ipiNeeds9Chars')) ||
                                    (songWriterIpiIsNotUnique &&
                                        formatMessage(
                                            'errors.songWriterIPICollision'
                                        )))) ||
                            undefined
                        }
                    >
                        <NumberInput
                            value={ipi || ''}
                            onChange={(value: string) =>
                                onIpiChange(value, setIpi)
                            }
                            allowNegatives={false}
                            placeholder={formatMessage(
                                'songWriterManager.songwriterIpiPlaceholder'
                            )}
                        />
                    </Field>
                </div>
                {isPublisherEditAllow && songWriter?.agreements && (
                    <PublishersComponent
                        label={songWriter.vendor}
                        publishers={publishers}
                        setPublishers={setPublishers}
                        onHasCompanyChanged={setHasPublishingCompany}
                        onAddNewPublisher={setNewPublisher}
                        isControlled={!!isControlled}
                    />
                )}
            </Form>
        </Sidecar>
    );
};

export default EditSongWriter;
