import { useIdentity } from '@theorchard/suite-frontend';
import { Decimal } from 'decimal.js';

export const getSum = (decimals: Decimal[]) => {
    let total = new Decimal(0);
    decimals.forEach(decimal => {
        total = total.plus(decimal);
    });
    return total;
};

export const emptySongWriter = {
    id: '',
    agreementId: '',
    split: '0',
    pro: null,
    ipi: null,
    legalName: '',
    controlled: false,
    hasMusicContribution: false,
    hasLyricsContribution: false,
    publisherName: '',
};

export const useIsEmployee = () => {
    const identity = useIdentity();
    return !!identity.isEmployee;
};

export const useIsEmployeeWithFullAccess = () => {
    const identity = useIdentity();
    return (
        !!identity.isEmployee && !!identity.resources?.some(i => i.id === '*')
    );
};
