import React from 'react';
import { LoadingIndicator } from '@theorchard/suite-components';
import { useParams } from 'react-router-dom';
import ReadError from 'src/components/errors/read-error';
import {
    useComposition,
    useGlobalSoundRecording,
    useLabelSoundRecording,
} from 'src/data/queries';
import {
    gsrToAssociatedSoundRecording,
    lsrToAssociatedSoundRecording,
} from './helpers';
import NewSong from './newSong';

const NewSongGql: React.FC = () => {
    const urlParams = new URLSearchParams(window.location.search);
    const { id } = useParams<{ id: string }>();
    const gsrId = urlParams.get('gsrId') || '';
    const lsrId = urlParams.get('lsrId') || '';
    const { data, loading, error } = useGlobalSoundRecording(gsrId);
    const {
        data: lsrData,
        loading: lsrLoading,
        error: lsrError,
    } = useLabelSoundRecording(lsrId);
    const {
        data: composition,
        loading: loadingComp,
        error: errorComp,
    } = useComposition(id);

    if (loading || loadingComp || lsrLoading) return <LoadingIndicator />;

    if (error || errorComp || lsrError)
        return <ReadError error={error || errorComp || lsrError} />;

    return (
        <NewSong
            associatedSoundRecording={
                (data && gsrToAssociatedSoundRecording(data)) ||
                (lsrData && lsrToAssociatedSoundRecording(lsrData)) ||
                null
            }
            composition={composition}
        />
    );
};

export default NewSongGql;
