import { useApolloClient, useMutation } from '@apollo/client';
import deleteSongWriter from './deleteSongWriter.gql';

export const useDeleteSongWriter = () => {
    const client = useApolloClient();

    const [mutation] = useMutation(deleteSongWriter, {
        update() {
            // eslint-disable-next-line @typescript-eslint/no-floating-promises
            client.resetStore();
        },
    });
    return mutation;
};
