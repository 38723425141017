import type {
    PublishingCompositionsListingFilter,
    PublishingSongWritersFilterLabelInput,
} from 'src/data/queries';
export const newSongUrl = () => '/song/new';
export const bulkIngestionUrl = () => '/bulk-ingestion';
export const newSongFormUrl = (gsrId = '') => `/song/new/form?gsrId=${gsrId}`;
export const newSongFormUrlFromLsr = (lsrId = '') =>
    `/song/new/form?lsrId=${lsrId}`;
export const songUrl = (id: string) => `/song/${id}`;
export const publishersListUrl = (page: number) => `/publishers/${page}`;
export const songsDraftsUrl = (page: number) => `/songs/drafts?page=${page}`;
export const songsSubmittedUrl = (page: number) =>
    `/songs/submitted?page=${page}`;

export const songsUrlFromFilter = (
    tab: string,
    filter: PublishingCompositionsListingFilter,
    isEmployeeWithFullAccess: boolean = false
) => ({
    pathname: `/songs/${tab}`,
    search:
        `?page=${filter.page}` +
        `&labelId=${filter.label?.id.vendorId || ''}` +
        `&term=${encodeURIComponent(filter.titleSearch || '')}` +
        `&songWriters=${encodeURIComponent(filter.songWriters?.join(',') || '')}` +
        (isEmployeeWithFullAccess ? `&brand=${filter.brand || ''}` : ''),
});

export const songWritersUrl = (
    page: string | number,
    { brand }: PublishingSongWritersFilterLabelInput,
    isEmployeeWithFullAccess: boolean = true
) => ({
    pathname: `/song-writers/${page}`,
    search: isEmployeeWithFullAccess && brand ? `?brand=${brand}` : '',
});
