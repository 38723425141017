import { useCallback, useEffect } from 'react';
import { debounce } from 'lodash';
import type { DebouncedFunc } from 'lodash';

export type AnyFunction = Parameters<typeof debounce>[0];
export type DebounceType = <T extends AnyFunction>(
    ...args: [T, number?]
) => DebouncedFunc<T>;
export type InputChangeCallback = (
    term: string,
    actions: { action: string }
) => void;

export const useDebounceCallback: DebounceType = (callback, time = 0) => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const debouncedCallback = useCallback(debounce(callback, time), [
        callback,
        time,
    ]);
    useEffect(() => () => debouncedCallback.cancel(), [debouncedCallback]);
    return debouncedCallback;
};
