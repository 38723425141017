import React from 'react';
import { HelpTooltip } from '@theorchard/suite-components';
import { formatMessage } from '@theorchard/suite-frontend';
import { drop, take } from 'lodash';
import { CLASSNAME } from 'src/pages/songListPage/songListPage';
import type { CompositionExtended } from 'src/types';

interface Props {
    agreements: CompositionExtended['agreements'];
}

const SongWritersList: React.FC<Props> = ({ agreements }) => {
    if (agreements.length === 0)
        return (
            <div className={`${CLASSNAME}-none`}>
                {formatMessage('songs.noneAdded')}
            </div>
        );

    const tags = take(agreements, 3).map(
        ({ agreement: { controlled, songWriter } }, i: number) => (
            <React.Fragment key={i}>
                <div
                    className={`${CLASSNAME}-tag ${controlled ? '' : `${CLASSNAME}-tag-uncontrolled`}`}
                >
                    {songWriter?.legalName || ''}
                </div>
            </React.Fragment>
        )
    );

    if (agreements.length > 3)
        return (
            <div>
                {tags}

                <HelpTooltip
                    message={drop(agreements, 3)
                        .map(
                            ({ agreement: { songWriter } }) =>
                                songWriter.legalName
                        )
                        .join(', ')}
                    id="songwriters-tooltip"
                >
                    <span className={`${CLASSNAME}-more-link`}>
                        {' '}
                        + {agreements.length - 3}{' '}
                        <span className={`${CLASSNAME}-more-link-word`}>
                            {formatMessage('generic.more')}
                        </span>
                    </span>
                </HelpTooltip>
            </div>
        );

    return <>{tags}</>;
};

export default SongWritersList;
