import React from 'react';
import { Table } from '@theorchard/suite-components';
import { formatMessage } from '@theorchard/suite-frontend';
import { useHistory } from 'react-router-dom';
import { newSongFormUrl, newSongFormUrlFromLsr } from 'src/utils/urls';
import type {
    LabelParticipantSearchItems,
    OwsSearchProducts,
    OwsSearchTracks,
} from 'src/types';

interface Props {
    labelParticipants: LabelParticipantSearchItems;
    owsSearchTracks: OwsSearchTracks;
    owsSearchProducts: OwsSearchProducts;
}

const CatalogSongTable: React.FC<Props> = ({
    labelParticipants,
    owsSearchTracks,
    owsSearchProducts,
}) => {
    const history = useHistory();

    return (
        <div>
            <Table>
                <thead>
                    <tr>
                        <th>{formatMessage('generic.track')}</th>
                        <th>{formatMessage('generic.songWriters')}</th>
                        <th>
                            {formatMessage('selectExistingSong.productUpc')}
                        </th>
                        <th>{formatMessage('generic.releaseDate')}</th>
                        <th>
                            {formatMessage('selectExistingSong.allTimeStreams')}
                        </th>
                        <th />
                    </tr>
                </thead>

                <tbody>
                    {owsSearchProducts.map(product =>
                        product.tracks?.map(track => (
                            <tr
                                key={track.labelSoundRecording?.id}
                                onClick={() =>
                                    history.push(
                                        track.labelSoundRecording
                                            ?.globalSoundRecording?.id
                                            ? newSongFormUrl(
                                                  track.labelSoundRecording
                                                      ?.globalSoundRecording?.id
                                              )
                                            : newSongFormUrlFromLsr(
                                                  track.labelSoundRecording?.id
                                              )
                                    )
                                }
                            >
                                <td>
                                    <div className="SelectExistingSong-table-track">
                                        {product.imageLocation && (
                                            <img
                                                className="SelectExistingSong-table-img"
                                                alt="Song art"
                                                height={30}
                                                width={30}
                                                src={product.imageLocation}
                                            />
                                        )}
                                        <div>
                                            <div>{track.trackName}</div>
                                            <div className="SelectExistingSong-table-name">
                                                {track.primaryArtists
                                                    ?.filter(
                                                        pa =>
                                                            pa?.artistType ===
                                                            'performer'
                                                    )
                                                    .map(pa => pa?.artistName)
                                                    .join(', ')}
                                            </div>
                                        </div>
                                    </div>
                                </td>
                                <td>
                                    <div>
                                        {track.writers
                                            ?.filter(w => w?.type === 'writer')
                                            .map(w => w?.name)
                                            .join(', ')}
                                    </div>
                                </td>
                                <td>
                                    <div>{product.productName}</div>
                                    <div className="SelectExistingSong-table-upc">
                                        {product.upc}
                                    </div>
                                </td>
                                <td>
                                    {product.releaseDate
                                        ? new Date(
                                              product.releaseDate
                                          ).toLocaleDateString()
                                        : ''}
                                </td>
                                <td>
                                    {track.analytics?.streams.aggregate.allTime}
                                </td>
                            </tr>
                        ))
                    )}
                    {owsSearchTracks.map(track => (
                        <tr
                            key={track.labelSoundRecording?.id}
                            onClick={() =>
                                history.push(
                                    track.labelSoundRecording
                                        ?.globalSoundRecording?.id
                                        ? newSongFormUrl(
                                              track.labelSoundRecording
                                                  ?.globalSoundRecording?.id
                                          )
                                        : newSongFormUrlFromLsr(
                                              track.labelSoundRecording?.id
                                          )
                                )
                            }
                        >
                            <td>
                                <div className="SelectExistingSong-table-track">
                                    {track.product?.imageLocation && (
                                        <img
                                            className="SelectExistingSong-table-img"
                                            alt="Song art"
                                            height={30}
                                            width={30}
                                            src={track.product.imageLocation}
                                        />
                                    )}
                                    <div>
                                        <div>{track.trackName}</div>
                                        <div className="SelectExistingSong-table-name">
                                            {track.participations
                                                .filter(
                                                    p =>
                                                        p.participatedAs ===
                                                        'performer'
                                                )
                                                .map(p => p.participant?.name)
                                                .join(', ')}
                                        </div>
                                    </div>
                                </div>
                            </td>
                            <td>
                                <div>
                                    {track.participations
                                        .filter(
                                            p =>
                                                p.participatedAs ===
                                                'track_writer'
                                        )
                                        .map(p => p.participant?.name)
                                        .join(', ')}
                                </div>
                            </td>
                            <td>
                                <div>{track.product?.productName}</div>
                                <div className="SelectExistingSong-table-upc">
                                    {track.product?.upc}
                                </div>
                            </td>
                            <td>
                                {track.product?.releaseDate
                                    ? new Date(
                                          track.product?.releaseDate
                                      ).toLocaleDateString()
                                    : ''}
                            </td>
                            <td>
                                {track.analytics?.streams.aggregate.allTime}
                            </td>
                        </tr>
                    ))}

                    {labelParticipants.map(labelParticipant =>
                        labelParticipant.item.trackParticipations.map(
                            trackParticipation => (
                                <tr
                                    key={trackParticipation.track.tuid}
                                    onClick={() =>
                                        history.push(
                                            newSongFormUrl(
                                                trackParticipation.track
                                                    .labelSoundRecording
                                                    ?.globalSoundRecording?.id
                                            )
                                        )
                                    }
                                >
                                    <td>
                                        <div className="SelectExistingSong-table-track">
                                            {trackParticipation.track
                                                .product && (
                                                <img
                                                    className="SelectExistingSong-table-img"
                                                    alt="Song art"
                                                    height={30}
                                                    width={30}
                                                    src={
                                                        trackParticipation.track
                                                            .product
                                                            ?.imageLocation ||
                                                        undefined
                                                    }
                                                />
                                            )}
                                            <div>
                                                <div>
                                                    {
                                                        trackParticipation.track
                                                            .trackName
                                                    }
                                                </div>
                                                <div className="SelectExistingSong-table-name">
                                                    {trackParticipation.track.participations
                                                        ?.filter(
                                                            gp =>
                                                                gp.participatedAs ===
                                                                'performer'
                                                        )
                                                        .map(
                                                            gp =>
                                                                gp.participant
                                                                    ?.name
                                                        )
                                                        .join(', ')}
                                                </div>
                                            </div>
                                        </div>
                                    </td>
                                    <td>
                                        <div>
                                            {trackParticipation.track.participations
                                                ?.filter(
                                                    gp =>
                                                        gp.participatedAs ===
                                                        'track_writer'
                                                )
                                                .map(gp => gp.participant?.name)
                                                .join(', ')}
                                        </div>
                                    </td>
                                    <td>
                                        <div>
                                            {
                                                trackParticipation.track.product
                                                    ?.productName
                                            }
                                        </div>
                                        <div className="SelectExistingSong-table-upc">
                                            {
                                                trackParticipation.track.product
                                                    ?.upc
                                            }
                                        </div>
                                    </td>
                                    <td>
                                        {trackParticipation.track.product
                                            ?.releaseDate
                                            ? new Date(
                                                  trackParticipation.track.product?.releaseDate
                                              ).toLocaleDateString()
                                            : ''}
                                    </td>
                                    <td>
                                        {
                                            trackParticipation.track
                                                .labelSoundRecording
                                                ?.globalSoundRecording
                                                ?.analytics?.streams.aggregate
                                                .allTime
                                        }
                                    </td>
                                </tr>
                            )
                        )
                    )}
                </tbody>
            </Table>
        </div>
    );
};

export default CatalogSongTable;
