import { useApolloClient, useMutation } from '@apollo/client';
import deletePublisher from './deletePublisher.gql';

export const useDeletePublisherMutation = () => {
    const client = useApolloClient();

    const [mutation] = useMutation(deletePublisher, {
        update() {
            // eslint-disable-next-line @typescript-eslint/no-floating-promises
            client.resetStore();
        },
    });
    return mutation;
};
