import type { FC } from 'react';
import React, { useCallback, useEffect, useState, useMemo } from 'react';
import { Card, LoadingIndicator } from '@theorchard/suite-components';
import { useHistory } from 'react-router-dom';
import EditPublisher from 'src/components/editPublisher';
import EmptyState from 'src/components/emptyState';
import ReadError from 'src/components/errors/read-error';
import HeaderLabelPicker from 'src/components/headerLabelPicker';
import { SHOW_ALL_LABEL_UUID } from 'src/components/headerLabelPicker/labelSearchDropdown';
import ViewPublisher from 'src/components/viewPublisher';
import { useDeletePublisherMutation } from 'src/data/mutations/deletePublisher/deletePublisher';
import { usePublishingPublishers } from 'src/data/queries';
import PageControls from 'src/pages/publishersListPage/pageControls';
import PublishersTable from 'src/pages/publishersListPage/publishersTable';
import { useApplicationContext } from 'src/utils/applicationContext';
import { useLocationWithParams } from 'src/utils/route';
import { publishersListUrl } from 'src/utils/urls';
import { isValidIpi } from 'src/utils/validators';
import type { PublishingPublishersFilter } from 'src/data/schemaTypes';
import type { Label, Publisher } from 'src/types';

export const CLASSNAME = 'PublishersListPage';
const enum DetailMode {
    View = 'view',
    Edit = 'edit',
}
export const PUBLISHERS_PER_PAGE = 25;

export interface PublishingPublishersFilterLabel
    extends PublishingPublishersFilter {
    label?: Label | null;
}

const PublishersListPage: FC = () => {
    const history = useHistory();

    const { label: commonLabel } = useApplicationContext();

    const deletePublisher = useDeletePublisherMutation();

    const { params } = useLocationWithParams();
    const page = parseInt(params[0] || '1', 10);

    const [filter, setFilter] = useState<PublishingPublishersFilterLabel>({
        label: null,
    });
    const [selectedPublisher, setSelectedPublisher] =
        useState<Publisher | null>(null);
    const [detailMode, setDetailMode] = useState<string>('');
    const [pageSize, setPageSize] = useState(PUBLISHERS_PER_PAGE);

    const handlePageSizeChange = (newPageSize: number) => {
        setPageSize(newPageSize);
    };

    useEffect(() => {
        if (
            filter.label?.uuid === commonLabel?.uuid ||
            (commonLabel?.uuid === SHOW_ALL_LABEL_UUID && !filter.label)
        )
            return;

        setFilter(f => ({
            ...f,
            label:
                commonLabel && commonLabel.uuid !== SHOW_ALL_LABEL_UUID
                    ? commonLabel
                    : null,
        }));
    }, [commonLabel, filter]);

    const {
        data: publishersQueryData,
        loading,
        error,
    } = usePublishingPublishers(filter, page, pageSize);

    const onSearchChange = useCallback(
        (e: React.ChangeEvent<HTMLInputElement>) => {
            const inputValue = e.target.value;
            setFilter(f => {
                const { ipi, nameSearch, ...newFilter } = f;
                return isValidIpi(inputValue)
                    ? { ...newFilter, ipi: inputValue }
                    : { ...newFilter, nameSearch: inputValue };
            });
        },
        []
    );

    const onPaginationChange = useCallback(
        (newPage: number) => {
            history.push(publishersListUrl(newPage + 1));
        },
        [history]
    );

    const totalPages = useMemo(
        () => Math.ceil((publishersQueryData?.totalCount || 0) / pageSize),
        [publishersQueryData?.totalCount, pageSize]
    );

    // Check if the page number is valid and redirect if necessary to the first page when the publishers list changes
    // If the page number is greater than the total number of pages, redirect to the first page
    useEffect(() => {
        if (!loading && publishersQueryData?.totalCount !== undefined) {
            const newPageNumber =
                totalPages === 0 || page > totalPages ? 1 : page;
            if (newPageNumber !== page) {
                history.push(publishersListUrl(newPageNumber));
            }
        }
    }, [loading, publishersQueryData, page, history, totalPages]);

    const onPublisherView = useCallback((publisher: Publisher) => {
        setSelectedPublisher(publisher);
        setDetailMode(DetailMode.View);
    }, []);

    const onPublisherDelete = useCallback(
        ({ id }: Publisher) => {
            void deletePublisher({ variables: { id } });
        },
        [deletePublisher]
    );

    const onPublisherEdit = useCallback((publisher: Publisher) => {
        setSelectedPublisher(publisher);
        setDetailMode(DetailMode.Edit);
    }, []);

    const onDetailModeReset = useCallback(() => {
        setSelectedPublisher(null);
        setDetailMode('');
    }, []);

    if (!publishersQueryData) return <LoadingIndicator />;

    if (error) return <ReadError error={error} />;

    return (
        <div className={CLASSNAME}>
            {!!selectedPublisher && detailMode === DetailMode.View && (
                <ViewPublisher
                    isOpen
                    onClose={onDetailModeReset}
                    publisher={selectedPublisher}
                />
            )}

            {!!selectedPublisher && detailMode === DetailMode.Edit && (
                <EditPublisher
                    isOpen
                    onClose={onDetailModeReset}
                    publisher={selectedPublisher}
                />
            )}

            <div className="AppHeader">
                <HeaderLabelPicker />
            </div>

            <PageControls onSearchChange={onSearchChange} />

            <div className="PageContent">
                <Card body>
                    {!publishersQueryData.publishers?.length ? (
                        <EmptyState
                            isLoading={loading}
                            page="publishers"
                            showSubHeader={false}
                        />
                    ) : (
                        <PublishersTable
                            publishers={publishersQueryData.publishers}
                            page={page - 1}
                            totalPages={totalPages}
                            totalCount={publishersQueryData.totalCount || 0}
                            pageSize={pageSize}
                            onPaginationChange={onPaginationChange}
                            onPublisherView={onPublisherView}
                            onPublisherDelete={onPublisherDelete}
                            onPublisherEdit={onPublisherEdit}
                            onSetPageSize={handlePageSizeChange}
                        />
                    )}
                </Card>
            </div>
        </div>
    );
};

export default PublishersListPage;
