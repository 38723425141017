import React, { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import LabelSearchDropdown from 'src/components/headerLabelPicker/labelSearchDropdown';
import { useApplicationContext } from 'src/utils/applicationContext';
import type { Label } from 'src/types';

const CLASS_NAME = 'HeaderLabelPicker';
const HeaderLabelPicker: React.FC = () => {
    const { location } = useHistory();
    const { label, setLabel, labelAlt } = useApplicationContext();

    const isNewSongPage = location.pathname.includes('/song/new');
    const compositionEditPagePathRegex =
        /^\/song\/[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$/;
    const isCompositionEditPage = compositionEditPagePathRegex.test(
        location.pathname
    );

    const labelValue = isCompositionEditPage ? labelAlt : label;

    const onLabelSelect = useCallback(
        (value: Label | undefined) => setLabel(value || null),
        [setLabel]
    );

    return (
        <div className={CLASS_NAME}>
            <LabelSearchDropdown
                vendorId={labelValue?.id.vendorId}
                onChange={onLabelSelect}
                showAllOption={!isNewSongPage}
                disabled={isCompositionEditPage}
            />
        </div>
    );
};

export default HeaderLabelPicker;
