import React, { useCallback, useState } from 'react';
import { Button, Field, Form } from '@theorchard/suite-components';
import { formatMessage } from '@theorchard/suite-frontend';
import { PlusGlyph } from '@theorchard/suite-icons';
import AddPublisherComponent from 'src/components/editSongWriter/addPublisherComponent';
import PublisherComponent from 'src/components/editSongWriter/publisherComponent';
import type { Label, Publisher } from 'src/types';

export type ControlledPublisher = Publisher & {
    controlled: boolean;
};

interface Props {
    label: Label;
    publishers: ControlledPublisher[];
    setPublishers: (publishers: ControlledPublisher[]) => void;
    onHasCompanyChanged: (hasCompany: boolean) => void;
    onAddNewPublisher: (publisher: ControlledPublisher | null) => void;
    isControlled: boolean;
}

const PublishersComponent: React.FC<Props> = ({
    label,
    publishers,
    setPublishers,
    onHasCompanyChanged,
    onAddNewPublisher,
    isControlled,
}) => {
    const [hasPublishingCompany, setHasPublishingCompany] = useState<boolean>(
        !!publishers.length
    );
    const [isAddCompany, setIsAddCompany] = useState<boolean>(false);

    const onHasPublishingCompanyChanged = useCallback(
        (hasCompany: boolean) => {
            onHasCompanyChanged(hasCompany);
            setHasPublishingCompany(hasCompany);
        },
        [onHasCompanyChanged]
    );

    const onDeletePublisher = useCallback(
        (id: string) => {
            setPublishers(publishers.filter(p => p.id !== id));
        },
        [publishers, setPublishers]
    );

    const onDeleteNewPublisher = () => {
        onAddNewPublisher(null);
        setIsAddCompany(false);
    };

    return (
        <div className="PublishersComponent">
            <Field
                controlId="hasExistingPublishing"
                labelText={formatMessage(
                    'songWriterManager.doesHaveExistingPublishingCo'
                )}
                isRequired={isControlled}
            >
                <Form.Check
                    checked={hasPublishingCompany}
                    onChange={() => onHasPublishingCompanyChanged(true)}
                    name="hasExistingPublishing"
                    label={formatMessage('generic.yes')}
                    type="radio"
                    id="hasExistingPublishing1"
                />
                <Form.Check
                    checked={!hasPublishingCompany}
                    onChange={() => onHasPublishingCompanyChanged(false)}
                    name="hasExistingPublishing"
                    label={formatMessage('generic.no')}
                    type="radio"
                    id="hasExistingPublishing2"
                />
            </Field>
            {hasPublishingCompany && (
                <>
                    {publishers.map(p => (
                        <PublisherComponent
                            key={p.id}
                            publisher={p}
                            onDeletePublisher={onDeletePublisher}
                        />
                    ))}

                    {isAddCompany ? (
                        <AddPublisherComponent
                            label={label}
                            excludePublisherIds={publishers.map(
                                publisher => publisher.id
                            )}
                            onUpdatePublisher={onAddNewPublisher}
                            onDeleteNewPublisher={onDeleteNewPublisher}
                        />
                    ) : (
                        <Button
                            variant="link"
                            className="icon-left"
                            onClick={() => setIsAddCompany(true)}
                        >
                            <PlusGlyph />
                            {formatMessage(
                                'songWriterManager.addPublishingCompany'
                            )}
                        </Button>
                    )}
                </>
            )}
        </div>
    );
};

export default PublishersComponent;
