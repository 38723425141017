export const isValidIpi = (value: string) => {
    if (Number.isNaN(parseInt(value, 10))) return false;

    let formatted = value.slice(-11);
    if (value.length < 11) formatted = `00000000000${value}`.slice(-11);
    const weightedSum = formatted
        .slice(0, 9)
        .split('')
        .map((v, k) => (10 - k) * parseInt(v, 10))
        .reduce((a, b) => a + b, 0);
    const last2Digits = parseInt(formatted.slice(-2), 10);
    let checkDigit = 101 - (weightedSum % 101);
    if (checkDigit === 101 || checkDigit === 100) checkDigit = 0;
    return checkDigit === last2Digits;
};
