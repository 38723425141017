import { useQuery } from '@apollo/client';
import { LabelSoundRecordingDocument } from 'src/data/queries/labelSoundRecording/__generated__/labelSoundRecording';
import type { LabelSoundRecordingQuery } from 'src/data/queries/labelSoundRecording/__generated__/labelSoundRecording';

export const useLabelSoundRecording = (id: string) => {
    const { error, loading, data } = useQuery<LabelSoundRecordingQuery>(
        LabelSoundRecordingDocument,
        { variables: { id }, skip: id.length === 0 }
    );

    return { error, loading, data: data?.labelSoundRecording };
};
