import { gql } from '@apollo/client';

const SPOTIFY_OFFSET = 50;
export const SPOTIFY_LIMIT = 50;

const fragment = `
fragment SpotifyTrackFields on SpotifyTrack {
    id
    name
    album {
        id
        images {
            width
            height
            url
        }
    }
    artists {
        id
        name
    }
    externalIds {
        isrc
    }
}`;

export const generateQuery = (limit = 50) => {
    const batches = [];

    for (let i = 0; i < Math.ceil(limit / SPOTIFY_LIMIT); i++)
        batches.push(`
            part${i}:spotifyTrackSearch(term: $term, limit: $limit, offset: ${i > 0 ? SPOTIFY_OFFSET * i : '$offset'}) {
                items {
                  ...SpotifyTrackFields
                }
            }
        `);

    return gql`
        query SpotifyTrackSearch($term: String!, $limit: Int = 50, $offset: Int = 0) {
            ${batches.join('')}
        }
        ${fragment}
    `;
};
