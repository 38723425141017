import React from 'react';
import { Label } from '@theorchard/suite-components';
import { formatMessage } from '@theorchard/suite-frontend';
import { CloseGlyph } from '@theorchard/suite-icons';
import type { ControlledPublisher } from 'src/components/editSongWriter/publishersComponent';

const PublisherComponent: React.FC<{
    publisher: ControlledPublisher;
    onDeletePublisher: (id: string) => void;
}> = ({ publisher, onDeletePublisher }) => (
    <div className="publisher" key={publisher.id}>
        <div className="header">
            <Label text={formatMessage('generic.publishingCompanySelect')} />
            <div
                className="deletePublisher"
                onClick={() => onDeletePublisher(publisher.id)}
            >
                <CloseGlyph />
            </div>
        </div>
        <div className="name">
            {formatMessage('generic.name')}:&nbsp;{publisher.name}
        </div>
        <div className="details">
            <div className="ipi">
                {formatMessage('generic.ipi')}:&nbsp;{publisher.ipi}
            </div>
            <div className="pro">
                {formatMessage('generic.pro')}:&nbsp;{publisher.pro}
            </div>
            <div className="controlled">
                {formatMessage('generic.controlled')}:&nbsp;
                {publisher.controlled
                    ? formatMessage('generic.yes')
                    : formatMessage('generic.no')}
            </div>
        </div>
    </div>
);

export default PublisherComponent;
