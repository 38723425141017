import { useQuery } from '@apollo/client';
import { PublishingCompositionDocument } from 'src/data/queries/composition/__generated__/composition';
import type { PublishingCompositionQuery } from 'src/data/queries/composition/__generated__/composition';

export const useComposition = (id: string) => {
    const { error, loading, data } = useQuery<PublishingCompositionQuery>(
        PublishingCompositionDocument,
        { variables: { id }, skip: !id }
    );

    return { error, loading, data: data?.publishingComposition };
};
