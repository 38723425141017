import { useQuery } from '@apollo/client';
import { flattenError } from '@theorchard/suite-frontend';
import { OrchardLabelByIdDocument } from './__generated__/orchardLabelById';
import type {
    OrchardLabelByIdQuery,
    OrchardLabelByIdQueryVariables as Variables,
} from './__generated__/orchardLabelById';

export const useOrchardLabelByIdQuery = (variables: Variables) => {
    const { data, loading, error } = useQuery<OrchardLabelByIdQuery, Variables>(
        OrchardLabelByIdDocument,
        { skip: !variables.id.vendorId, variables }
    );

    return {
        loading,
        data: variables && data?.orchardLabel ? data.orchardLabel : undefined,
        error: error && flattenError(error),
    };
};
