/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

import * as Types from 'src/data/schemaTypes';

import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type LabelParticipantSearchQueryVariables = Types.Exact<{
    term: Types.Scalars['String']['input'];
}>;

export type LabelParticipantSearchQuery = {
    __typename?: 'Query';
    activeProfile: {
        __typename?: 'Profile';
        labelParticipantSearchV2: {
            __typename?: 'LabelParticipantSearchResult';
            totalCount: number;
            items: Array<{
                __typename?: 'LabelParticipantSearchResultScoredItem';
                item: {
                    __typename?: 'LabelParticipant';
                    uuid: string;
                    name: string;
                    trackParticipations: Array<{
                        __typename?: 'LabelTrackParticipation';
                        participatedAs: string;
                        track: {
                            __typename?: 'Track';
                            tuid: string;
                            trackName?: string | null;
                            isrc?: string | null;
                            participations: Array<{
                                __typename?: 'LabelTrackParticipation';
                                participatedAs: string;
                                participant?: {
                                    __typename?: 'LabelParticipant';
                                    uuid: string;
                                    name: string;
                                } | null;
                            }>;
                            product?: {
                                __typename?: 'Product';
                                imageLocation?: string | null;
                                productName: string;
                                releaseDate?: string | null;
                                upc?: string | null;
                                productId: number;
                            } | null;
                            labelSoundRecording?: {
                                __typename?: 'LabelSoundRecording';
                                id: string;
                                globalSoundRecording?: {
                                    __typename?: 'GlobalSoundRecording';
                                    id: string;
                                    analytics?: {
                                        __typename?: 'GlobalSoundRecordingAnalytics';
                                        streams: {
                                            __typename?: 'Streams';
                                            aggregate: {
                                                __typename?: 'StreamData';
                                                allTime?: number | null;
                                            };
                                        };
                                    } | null;
                                } | null;
                            } | null;
                        };
                    }>;
                };
            }>;
        };
    };
};

export const LabelParticipantSearchDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'query',
            name: { kind: 'Name', value: 'LabelParticipantSearch' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'term' },
                    },
                    type: {
                        kind: 'NonNullType',
                        type: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'String' },
                        },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'activeProfile' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: {
                                        kind: 'Name',
                                        value: 'labelParticipantSearchV2',
                                    },
                                    arguments: [
                                        {
                                            kind: 'Argument',
                                            name: {
                                                kind: 'Name',
                                                value: 'term',
                                            },
                                            value: {
                                                kind: 'Variable',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'term',
                                                },
                                            },
                                        },
                                        {
                                            kind: 'Argument',
                                            name: {
                                                kind: 'Name',
                                                value: 'offset',
                                            },
                                            value: {
                                                kind: 'IntValue',
                                                value: '0',
                                            },
                                        },
                                        {
                                            kind: 'Argument',
                                            name: {
                                                kind: 'Name',
                                                value: 'first',
                                            },
                                            value: {
                                                kind: 'IntValue',
                                                value: '100',
                                            },
                                        },
                                    ],
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'totalCount',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'items',
                                                },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'item',
                                                            },
                                                            selectionSet: {
                                                                kind: 'SelectionSet',
                                                                selections: [
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'uuid',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'name',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'trackParticipations',
                                                                        },
                                                                        arguments:
                                                                            [
                                                                                {
                                                                                    kind: 'Argument',
                                                                                    name: {
                                                                                        kind: 'Name',
                                                                                        value: 'filter',
                                                                                    },
                                                                                    value: {
                                                                                        kind: 'ObjectValue',
                                                                                        fields: [
                                                                                            {
                                                                                                kind: 'ObjectField',
                                                                                                name: {
                                                                                                    kind: 'Name',
                                                                                                    value: 'participated_as',
                                                                                                },
                                                                                                value: {
                                                                                                    kind: 'StringValue',
                                                                                                    value: 'track_writer',
                                                                                                    block: false,
                                                                                                },
                                                                                            },
                                                                                        ],
                                                                                    },
                                                                                },
                                                                            ],
                                                                        selectionSet:
                                                                            {
                                                                                kind: 'SelectionSet',
                                                                                selections:
                                                                                    [
                                                                                        {
                                                                                            kind: 'Field',
                                                                                            name: {
                                                                                                kind: 'Name',
                                                                                                value: 'participatedAs',
                                                                                            },
                                                                                        },
                                                                                        {
                                                                                            kind: 'Field',
                                                                                            name: {
                                                                                                kind: 'Name',
                                                                                                value: 'track',
                                                                                            },
                                                                                            selectionSet:
                                                                                                {
                                                                                                    kind: 'SelectionSet',
                                                                                                    selections:
                                                                                                        [
                                                                                                            {
                                                                                                                kind: 'Field',
                                                                                                                name: {
                                                                                                                    kind: 'Name',
                                                                                                                    value: 'tuid',
                                                                                                                },
                                                                                                            },
                                                                                                            {
                                                                                                                kind: 'Field',
                                                                                                                name: {
                                                                                                                    kind: 'Name',
                                                                                                                    value: 'trackName',
                                                                                                                },
                                                                                                            },
                                                                                                            {
                                                                                                                kind: 'Field',
                                                                                                                name: {
                                                                                                                    kind: 'Name',
                                                                                                                    value: 'isrc',
                                                                                                                },
                                                                                                            },
                                                                                                            {
                                                                                                                kind: 'Field',
                                                                                                                name: {
                                                                                                                    kind: 'Name',
                                                                                                                    value: 'participations',
                                                                                                                },
                                                                                                                selectionSet:
                                                                                                                    {
                                                                                                                        kind: 'SelectionSet',
                                                                                                                        selections:
                                                                                                                            [
                                                                                                                                {
                                                                                                                                    kind: 'Field',
                                                                                                                                    name: {
                                                                                                                                        kind: 'Name',
                                                                                                                                        value: 'participatedAs',
                                                                                                                                    },
                                                                                                                                },
                                                                                                                                {
                                                                                                                                    kind: 'Field',
                                                                                                                                    name: {
                                                                                                                                        kind: 'Name',
                                                                                                                                        value: 'participant',
                                                                                                                                    },
                                                                                                                                    selectionSet:
                                                                                                                                        {
                                                                                                                                            kind: 'SelectionSet',
                                                                                                                                            selections:
                                                                                                                                                [
                                                                                                                                                    {
                                                                                                                                                        kind: 'Field',
                                                                                                                                                        name: {
                                                                                                                                                            kind: 'Name',
                                                                                                                                                            value: 'uuid',
                                                                                                                                                        },
                                                                                                                                                    },
                                                                                                                                                    {
                                                                                                                                                        kind: 'Field',
                                                                                                                                                        name: {
                                                                                                                                                            kind: 'Name',
                                                                                                                                                            value: 'name',
                                                                                                                                                        },
                                                                                                                                                    },
                                                                                                                                                ],
                                                                                                                                        },
                                                                                                                                },
                                                                                                                            ],
                                                                                                                    },
                                                                                                            },
                                                                                                            {
                                                                                                                kind: 'Field',
                                                                                                                name: {
                                                                                                                    kind: 'Name',
                                                                                                                    value: 'product',
                                                                                                                },
                                                                                                                selectionSet:
                                                                                                                    {
                                                                                                                        kind: 'SelectionSet',
                                                                                                                        selections:
                                                                                                                            [
                                                                                                                                {
                                                                                                                                    kind: 'Field',
                                                                                                                                    name: {
                                                                                                                                        kind: 'Name',
                                                                                                                                        value: 'imageLocation',
                                                                                                                                    },
                                                                                                                                },
                                                                                                                                {
                                                                                                                                    kind: 'Field',
                                                                                                                                    name: {
                                                                                                                                        kind: 'Name',
                                                                                                                                        value: 'productName',
                                                                                                                                    },
                                                                                                                                },
                                                                                                                                {
                                                                                                                                    kind: 'Field',
                                                                                                                                    name: {
                                                                                                                                        kind: 'Name',
                                                                                                                                        value: 'releaseDate',
                                                                                                                                    },
                                                                                                                                },
                                                                                                                                {
                                                                                                                                    kind: 'Field',
                                                                                                                                    name: {
                                                                                                                                        kind: 'Name',
                                                                                                                                        value: 'upc',
                                                                                                                                    },
                                                                                                                                },
                                                                                                                                {
                                                                                                                                    kind: 'Field',
                                                                                                                                    name: {
                                                                                                                                        kind: 'Name',
                                                                                                                                        value: 'productId',
                                                                                                                                    },
                                                                                                                                },
                                                                                                                            ],
                                                                                                                    },
                                                                                                            },
                                                                                                            {
                                                                                                                kind: 'Field',
                                                                                                                name: {
                                                                                                                    kind: 'Name',
                                                                                                                    value: 'labelSoundRecording',
                                                                                                                },
                                                                                                                selectionSet:
                                                                                                                    {
                                                                                                                        kind: 'SelectionSet',
                                                                                                                        selections:
                                                                                                                            [
                                                                                                                                {
                                                                                                                                    kind: 'Field',
                                                                                                                                    name: {
                                                                                                                                        kind: 'Name',
                                                                                                                                        value: 'id',
                                                                                                                                    },
                                                                                                                                },
                                                                                                                                {
                                                                                                                                    kind: 'Field',
                                                                                                                                    name: {
                                                                                                                                        kind: 'Name',
                                                                                                                                        value: 'globalSoundRecording',
                                                                                                                                    },
                                                                                                                                    selectionSet:
                                                                                                                                        {
                                                                                                                                            kind: 'SelectionSet',
                                                                                                                                            selections:
                                                                                                                                                [
                                                                                                                                                    {
                                                                                                                                                        kind: 'Field',
                                                                                                                                                        name: {
                                                                                                                                                            kind: 'Name',
                                                                                                                                                            value: 'id',
                                                                                                                                                        },
                                                                                                                                                    },
                                                                                                                                                    {
                                                                                                                                                        kind: 'Field',
                                                                                                                                                        name: {
                                                                                                                                                            kind: 'Name',
                                                                                                                                                            value: 'analytics',
                                                                                                                                                        },
                                                                                                                                                        selectionSet:
                                                                                                                                                            {
                                                                                                                                                                kind: 'SelectionSet',
                                                                                                                                                                selections:
                                                                                                                                                                    [
                                                                                                                                                                        {
                                                                                                                                                                            kind: 'Field',
                                                                                                                                                                            name: {
                                                                                                                                                                                kind: 'Name',
                                                                                                                                                                                value: 'streams',
                                                                                                                                                                            },
                                                                                                                                                                            selectionSet:
                                                                                                                                                                                {
                                                                                                                                                                                    kind: 'SelectionSet',
                                                                                                                                                                                    selections:
                                                                                                                                                                                        [
                                                                                                                                                                                            {
                                                                                                                                                                                                kind: 'Field',
                                                                                                                                                                                                name: {
                                                                                                                                                                                                    kind: 'Name',
                                                                                                                                                                                                    value: 'aggregate',
                                                                                                                                                                                                },
                                                                                                                                                                                                selectionSet:
                                                                                                                                                                                                    {
                                                                                                                                                                                                        kind: 'SelectionSet',
                                                                                                                                                                                                        selections:
                                                                                                                                                                                                            [
                                                                                                                                                                                                                {
                                                                                                                                                                                                                    kind: 'Field',
                                                                                                                                                                                                                    name: {
                                                                                                                                                                                                                        kind: 'Name',
                                                                                                                                                                                                                        value: 'allTime',
                                                                                                                                                                                                                    },
                                                                                                                                                                                                                },
                                                                                                                                                                                                            ],
                                                                                                                                                                                                    },
                                                                                                                                                                                            },
                                                                                                                                                                                        ],
                                                                                                                                                                                },
                                                                                                                                                                        },
                                                                                                                                                                    ],
                                                                                                                                                            },
                                                                                                                                                    },
                                                                                                                                                ],
                                                                                                                                        },
                                                                                                                                },
                                                                                                                            ],
                                                                                                                    },
                                                                                                            },
                                                                                                        ],
                                                                                                },
                                                                                        },
                                                                                    ],
                                                                            },
                                                                    },
                                                                ],
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    LabelParticipantSearchQuery,
    LabelParticipantSearchQueryVariables
>;
