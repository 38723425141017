import { useQuery } from '@apollo/client';
import {
    GlobalSoundRecordingDocument,
    type GlobalSoundRecordingQuery,
} from 'src/data/queries/globalSoundRecording/__generated__/globalSoundRecording';

export const useGlobalSoundRecording = (id: string) => {
    const { error, loading, data } = useQuery<GlobalSoundRecordingQuery>(
        GlobalSoundRecordingDocument,
        { variables: { id }, skip: id.length === 0 }
    );

    return { error, loading, data: data?.globalSoundRecordingById };
};
