/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

import * as Types from 'src/data/schemaTypes';

import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type GlobalSoundRecordingQueryVariables = Types.Exact<{
    id: Types.Scalars['ID']['input'];
}>;

export type GlobalSoundRecordingQuery = {
    __typename?: 'Query';
    globalSoundRecordingById?: {
        __typename?: 'GlobalSoundRecording';
        id: string;
        name: string;
        isrc: string;
        globalParticipants?: Array<{
            __typename?: 'GlobalParticipant';
            id: string;
            name?: string | null;
        } | null> | null;
    } | null;
};

export const GlobalSoundRecordingDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'query',
            name: { kind: 'Name', value: 'GlobalSoundRecording' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'id' },
                    },
                    type: {
                        kind: 'NonNullType',
                        type: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'ID' },
                        },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: {
                            kind: 'Name',
                            value: 'globalSoundRecordingById',
                        },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'id' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'id' },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'name' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'isrc' },
                                },
                                {
                                    kind: 'Field',
                                    name: {
                                        kind: 'Name',
                                        value: 'globalParticipants',
                                    },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'id',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'name',
                                                },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    GlobalSoundRecordingQuery,
    GlobalSoundRecordingQueryVariables
>;
