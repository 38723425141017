import { useLazyQuery, useQuery } from '@apollo/client';
import { omit } from 'lodash';
import { PublishingCompositionsDocument } from 'src/data/queries/compositions/__generated__/compositions';
import { SONGS_PER_PAGE } from 'src/pages/songListPage/songListPage';
import type { PublishingCompositionsQuery } from 'src/data/queries/compositions/__generated__/compositions';
import type { PublishingCompositionsFilter } from 'src/data/schemaTypes';
import type { Label } from 'src/types';

export interface PublishingCompositionsListingFilter
    extends Omit<PublishingCompositionsFilter, 'label'> {
    label: Label | null;
    page: number;
}
const defaultFilter = { label: null, page: 1 };

export const useCompositions = (
    filter: PublishingCompositionsListingFilter = defaultFilter,
    limit: number = SONGS_PER_PAGE
) => {
    const { page } = filter;
    const offset = (page - 1) * limit;
    const uuid = filter?.label?.uuid;

    const { error, loading, data } = useQuery<PublishingCompositionsQuery>(
        PublishingCompositionsDocument,
        {
            notifyOnNetworkStatusChange: true,
            variables: {
                filter: {
                    ...omit(filter, 'page'),
                    label: uuid ? { uuid } : null,
                },
                limit,
                offset,
            },
        }
    );

    const compositions = data?.publishingCompositions?.compositions;

    return {
        error,
        loading,
        data: {
            totalCount: data?.publishingCompositions?.totalCount,
            compositions,
        },
    };
};

export const useLazyCheckSameTitleCompositionExistsQuery = (
    onSearchedTitleCallback: (songId?: string) => void
) =>
    useLazyQuery<PublishingCompositionsQuery>(PublishingCompositionsDocument, {
        onCompleted: data =>
            onSearchedTitleCallback(
                data?.publishingCompositions?.compositions?.[0]?.id
            ),
    });
