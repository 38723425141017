import { useMutation, useApolloClient } from '@apollo/client';
import { useHistory } from 'react-router-dom';
import { songsDraftsUrl } from 'src/utils/urls';
import createComposition from './createComposition.gql';

export const useCreateComposition = () => {
    const client = useApolloClient();
    const history = useHistory();

    const [mutation] = useMutation(createComposition, {
        update() {
            // eslint-disable-next-line @typescript-eslint/no-floating-promises
            client.resetStore();
        },
        onCompleted: () => {
            history.push(songsDraftsUrl(1));
        },
    });
    return mutation;
};
