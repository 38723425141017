import { differenceBy, minBy, uniqBy } from 'lodash';
import {
    useLazyGlobalSoundRecordingSearch,
    useLazyLabelParticipantSearch,
    useLazyOwsSearch,
    useLazySpotifyTrackSearch,
} from 'src/data/queries';
import type { ApolloError } from '@apollo/client';
import type {
    AssociatedSoundRecording as AssociatedSoundRecordingType,
    GlobalSoundRecordingSearchItem,
    LabelParticipantSearchItem,
    OwsSearchProductsTrack,
    OwsSearchTracks,
    OwsTrackParticipation,
    SpotifyTrackSearchItems,
} from 'src/types';

interface Variables {
    term: string;
}

type LabelParticipantTrack =
    LabelParticipantSearchItem['trackParticipations'][0]['track'];

interface SoundRecordingType {
    id: string;
    isrc?: string | null;
    name: string | null;
    artists: string[];
    artworkUrl?: string | null;
    isLsr: boolean;
    isSpotify?: boolean;
}

type SoundRecordingResult = [
    (variables: Variables) => void,
    {
        isCalled: boolean;
        isLoading: boolean;
        error?: ApolloError;
        soundRecordings: SoundRecordingType[];
    },
];

const getSpotifyResults = (
    aggregatedWithAssociatedSounds: { isrc: string }[],
    spotifyResults: SpotifyTrackSearchItems
) => {
    const mapped = spotifyResults.map(s => {
        const smallest = minBy(s.album?.images, 'height');
        return {
            id: s.id,
            name: s.name,
            isrc: s.externalIds?.isrc,
            artists: s.artists?.map(p => p?.name ?? '') ?? [],
            artworkUrl: smallest?.url,
            isLsr: false,
            isSpotify: true,
        };
    });
    const spotify = uniqBy(mapped, 'isrc');
    return differenceBy(spotify, aggregatedWithAssociatedSounds, 'isrc');
};

const processResults = (
    owsSearchTracks: OwsSearchTracks,
    owsSearchProductTracks: OwsSearchProductsTrack[],
    participatedTracks: LabelParticipantTrack[],
    globalSoundRecordingTracks: GlobalSoundRecordingSearchItem[],
    associatedSounds: AssociatedSoundRecordingType[]
) => {
    const uniq = uniqBy(
        [
            ...owsSearchTracks,
            ...owsSearchProductTracks,
            ...participatedTracks,
            ...globalSoundRecordingTracks,
        ],
        'isrc'
    );

    const diffed = differenceBy(uniq, associatedSounds, 'isrc');

    type SupportedTrackType =
        | OwsSearchTracks[0]
        | OwsSearchProductsTrack
        | LabelParticipantTrack
        | GlobalSoundRecordingSearchItem;

    const extractId = (item: SupportedTrackType) => {
        if ('labelSoundRecording' in item && item.labelSoundRecording) {
            if (
                'globalSoundRecording' in item.labelSoundRecording &&
                item.labelSoundRecording.globalSoundRecording
            )
                return item.labelSoundRecording.globalSoundRecording.id;

            return item.labelSoundRecording.id;
        }

        if ('id' in item) return item.id;

        return '';
    };

    const extractArtists = (item: SupportedTrackType) => {
        const artists = [];
        if ('participations' in item)
            artists.push(
                ...(item.participations as OwsTrackParticipation[]).map(
                    (p: OwsTrackParticipation) => p.participant?.name || ''
                )
            );

        if ('primaryArtists' in item)
            artists.push(
                ...(item.primaryArtists?.map(p => p?.artistName || '') || [])
            );

        if ('globalSoundRecording' in item) {
            const gsr = item.globalSoundRecording;
            if (gsr.labelSoundRecordings[0])
                artists.push(
                    ...(item.globalSoundRecording.labelSoundRecordings[0].tracks[0].performers?.map(
                        p => p?.name || ''
                    ) || [])
                );
            if (gsr.publicSoundRecordings.length)
                artists.push(
                    ...gsr.publicSoundRecordings.flatMap(p =>
                        p.participants.map(i => i.name || '')
                    )
                );
        }

        return artists;
    };

    const extractName = (item: SupportedTrackType) =>
        'name' in item ? item.name : item.trackName;

    const extractImage = (item: SupportedTrackType) => {
        if ('product' in item) return item.product?.imageLocation;

        if ('imageUrl' in item) return item.imageUrl;

        return '';
    };

    const isLsr = (item: SupportedTrackType) => {
        if ('labelSoundRecording' in item && item.labelSoundRecording)
            return !(
                'globalSoundRecording' in item.labelSoundRecording &&
                item.labelSoundRecording.globalSoundRecording
            );

        return false;
    };

    return diffed.map(item => ({
        id: extractId(item),
        name: extractName(item) || '',
        isrc: item.isrc,
        artists: extractArtists(item),
        artworkUrl: extractImage(item),
        isLsr: isLsr(item),
        isSpotify: false,
    }));
};

export const useLazySoundRecordingSearch = (
    soundRecordingsToExclude?: AssociatedSoundRecordingType[]
): SoundRecordingResult => {
    const [
        doOwsSearch,
        {
            songs: owsSearchTracks,
            products: owsSearchProducts,
            loading: owsSearchLoading,
            called: owsSearchCalled,
            error: owsError,
        },
    ] = useLazyOwsSearch();

    const [
        doSongSearch,
        {
            songs: globalSoundRecordings,
            loading: gsrLoading,
            called: gsrCalled,
            error: gsrError,
        },
    ] = useLazyGlobalSoundRecordingSearch();

    const [
        doParticipantSearch,
        {
            participants: labelParticipants,
            loading: labelParticipantSearchLoading,
            called: participantsCalled,
            error: labelParticipantSearchError,
        },
    ] = useLazyLabelParticipantSearch();

    const [
        doSpotifySearch,
        {
            data: spotifyResults,
            loading: spotifyLoading,
            called: spotifyCalled,
            error: spotifyError,
        },
    ] = useLazySpotifyTrackSearch();

    const processedResults = processResults(
        owsSearchTracks,
        owsSearchProducts.map(p => p.tracks).flat(),
        labelParticipants
            .map(l => l.item.trackParticipations.map(t => t.track))
            .flat(),
        globalSoundRecordings.map(g => g.item).flat(),
        soundRecordingsToExclude || []
    );

    const uniqueSpotify = getSpotifyResults(
        [
            ...(soundRecordingsToExclude?.map(a => ({ isrc: a.isrc })) || []),
            ...processedResults.map(p => ({ isrc: p.isrc ?? '' })),
        ],
        spotifyResults
    );

    const error =
        owsError || gsrError || labelParticipantSearchError || spotifyError;
    const isLoading =
        owsSearchLoading ||
        gsrLoading ||
        labelParticipantSearchLoading ||
        spotifyLoading;
    const isCalled =
        owsSearchCalled || gsrCalled || participantsCalled || spotifyCalled;

    return [
        variables => {
            doSongSearch(variables);
            doSpotifySearch({ limit: 150, ...variables });
            doParticipantSearch(variables);
            doOwsSearch({
                productReleaseStatus: 'in_content',
                ...variables,
            });
        },
        {
            soundRecordings: [...processedResults, ...uniqueSpotify],
            error,
            isLoading,
            isCalled,
        },
    ];
};
