import { useHistory, useLocation } from 'react-router-dom';

export const useLocationSearchQuery = () =>
    new URLSearchParams(useLocation().search);

export const useLocationWithParams = () => {
    const { location } = useHistory();
    const { pathname, href } = location as unknown as Location;

    const params = pathname.substr(1).split('/');
    const baseName = params.shift();

    return {
        ...location,
        pathname: baseName,
        params,
        href,
    };
};
