import { useMutation, useApolloClient } from '@apollo/client';
import { useHistory } from 'react-router-dom';
import { PublishingUpdateCompositionDocument } from 'src/data/mutations/updateComposition/__generated__/updateComposition';
import { songsDraftsUrl, songsSubmittedUrl } from 'src/utils/urls';
import type { Composition } from 'src/types';

export const useUpdateComposition = (composition: Composition | null) => {
    const client = useApolloClient();
    const history = useHistory();

    const [mutation] = useMutation(PublishingUpdateCompositionDocument, {
        update() {
            // eslint-disable-next-line @typescript-eslint/no-floating-promises
            client.resetStore();
        },
        onCompleted: () => {
            if (!composition?.draft) history.push(songsSubmittedUrl(1));
            else history.push(songsDraftsUrl(1));
        },
    });
    return mutation;
};
