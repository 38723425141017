import { formatMessage } from '@theorchard/suite-frontend';
import { sum } from 'lodash';

export const titleError = (title: string) => {
    if (!title.trim()) return formatMessage('errors.required');

    if (title.length > 10000) return formatMessage('errors.tooLong');

    return undefined;
};

export const iswcError = (iswc: string) => {
    if (iswc.trim().length === 0) return undefined;

    const iswcMatch = /^T[-.]?(\d{3}[-.]?\d{3}[-.]?\d{3})[-.]?(\d)$/.exec(iswc);
    if (!iswcMatch) return formatMessage('errors.badIswcFormat');

    const numbersOnly = iswcMatch[1].replace(/\./g, '').replace(/-/, '');
    const checkDigit = parseInt(iswcMatch[2], 10);

    const summation =
        sum(
            [...Array.from(numbersOnly)].map((numberStr, index) => {
                const number = parseInt(numberStr, 10);
                return number * (index + 1);
            })
        ) + 1;

    const calcedCheckDigit = (10 - (summation % 10)) % 10;

    if (checkDigit !== calcedCheckDigit)
        return formatMessage('errors.iswcBadCheckDigit');

    return undefined;
};

export const alternateError = (alternate: string) => {
    if (!alternate.trim()) return formatMessage('errors.required');

    if (alternate.length > 10000) return formatMessage('errors.tooLong');

    return undefined;
};
