import type { FC } from 'react';
import React from 'react';
import { formatMessage } from '@theorchard/suite-frontend';

interface Props {
    page: string;
    isLoading: boolean;
    showSubHeader?: boolean;
}

export const CLASSNAME = 'EmptyState';

const EmptyState: FC<Props> = ({ page, isLoading, showSubHeader = true }) => (
    <div className={CLASSNAME}>
        <div className={`${CLASSNAME}-header`}>
            {isLoading
                ? formatMessage(`${page}.searching`)
                : formatMessage(`${page}.noItems`)}
        </div>

        {!isLoading && showSubHeader && (
            <div className={`${CLASSNAME}-sub-header`}>
                {formatMessage(`${page}.addByClickingNew`)}
            </div>
        )}
    </div>
);

export default EmptyState;
