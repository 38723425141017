import { useQuery } from '@apollo/client';
import { OrchardLabelsDocument } from './__generated__/orchardLabels';
import type { OrchardLabelsQuery } from './__generated__/orchardLabels';

export const useOrchardLabels = () => {
    const { data, loading, error } = useQuery<OrchardLabelsQuery>(
        OrchardLabelsDocument
    );
    return {
        loading,
        data: data?.orchardLabels,
        error,
    };
};
