import { useLazyQuery } from '@apollo/client';
import { GetIsrcEntitiesDocument } from 'src/data/queries/isrcEntities/__generated__/isrcEntities';
import type { ApolloError } from '@apollo/client';
import type {
    GetIsrcEntitiesQuery,
    GetIsrcEntitiesQueryVariables,
} from 'src/data/queries/isrcEntities/__generated__/isrcEntities';

type IsrcEntitiesResult = [
    (variables: GetIsrcEntitiesQueryVariables) => void,
    {
        loading: boolean;
        error?: ApolloError;
        entities?: GetIsrcEntitiesQuery['isrc'] | null;
    },
];

export const useLazyIsrcEntities = (): IsrcEntitiesResult => {
    const [doIsrcEntitiesSearch, isrcEntitiesResult] =
        useLazyQuery<GetIsrcEntitiesQuery>(GetIsrcEntitiesDocument);

    const entities = isrcEntitiesResult.data?.isrc;

    return [
        (variables: GetIsrcEntitiesQueryVariables) => {
            if (variables.isrc)
                void doIsrcEntitiesSearch({ variables: { ...variables } });
        },
        { ...isrcEntitiesResult, entities },
    ];
};
