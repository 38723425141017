import type { FC } from 'react';
import React from 'react';
import { Sidecar } from '@theorchard/suite-components';
import { formatMessage } from '@theorchard/suite-frontend';
import type { Publisher } from 'src/types';

const CLASS_NAME = 'ViewPublisher';

interface Props {
    isOpen: boolean;
    onClose: () => void;
    publisher: Publisher;
}

const ViewPublisher: FC<Props> = ({ isOpen, onClose, publisher }) => {
    const songWriters =
        publisher?.agreements
            .filter(a => a.songWriter)
            .map(a => a.songWriter) || [];

    return (
        <Sidecar
            isOpen={isOpen}
            onRequestClose={onClose}
            className={CLASS_NAME}
            header={formatMessage('viewPublisher.title')}
        >
            <div>
                <div>
                    <div className={`${CLASS_NAME}-label`}>
                        {formatMessage('publishers.publisherLabelName')}
                    </div>
                    <div className={`${CLASS_NAME}-value`}>
                        {publisher.vendor?.name} -{' '}
                        {publisher.vendor?.id.vendorId}
                    </div>
                </div>
                <div>
                    <div className={`${CLASS_NAME}-label`}>
                        {formatMessage('publishers.publisherLegalName')}
                    </div>
                    <div className={`${CLASS_NAME}-value`}>
                        {publisher.name}
                    </div>
                </div>
                {publisher?.pro && (
                    <div>
                        <div className={`${CLASS_NAME}-label`}>
                            {formatMessage('publishers.publisherPro')}
                        </div>
                        <div className={`${CLASS_NAME}-value`}>
                            {publisher.pro}
                        </div>
                    </div>
                )}

                {publisher?.ipi && (
                    <div>
                        <div className={`${CLASS_NAME}-label`}>
                            {formatMessage('publishers.publisherIpi')}
                        </div>
                        <div className={`${CLASS_NAME}-value`}>
                            {publisher.ipi}
                        </div>
                    </div>
                )}

                {songWriters.map(sw => (
                    <div key={sw?.id} className={`${CLASS_NAME}-block`}>
                        <div>
                            <div className={`${CLASS_NAME}-label`}>
                                {formatMessage('generic.publishingSongWriter')}
                            </div>
                            <div className={`${CLASS_NAME}-value`}>
                                {sw?.legalName}
                            </div>
                        </div>

                        {sw?.pro && (
                            <div>
                                <div className={`${CLASS_NAME}-label`}>
                                    {formatMessage(
                                        'generic.publishingSongWriterPro'
                                    )}
                                </div>
                                <div className="ViewSongWriter-value">
                                    {sw?.pro}
                                </div>
                            </div>
                        )}

                        {sw?.ipi && (
                            <div>
                                <div className={`${CLASS_NAME}-label`}>
                                    {formatMessage(
                                        'generic.publishingSongWriterIpi'
                                    )}
                                </div>
                                <div className={`${CLASS_NAME}-value`}>
                                    {sw?.ipi}
                                </div>
                            </div>
                        )}
                    </div>
                ))}
            </div>
        </Sidecar>
    );
};

export default ViewPublisher;
