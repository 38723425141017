import { useRef } from 'react';
import { useLazyQuery } from '@apollo/client';
import { GlobalSoundRecordingSearchDocument } from 'src/data/queries/globalSoundRecordingSearch/__generated__/globalSoundRecordingSearch';
import type { ApolloError } from '@apollo/client';
import type {
    GlobalSoundRecordingSearchQuery,
    GlobalSoundRecordingSearchQueryVariables,
} from 'src/data/queries/globalSoundRecordingSearch/__generated__/globalSoundRecordingSearch';
import type { GlobalSoundRecordingSearchItems } from 'src/types';

interface Variables extends GlobalSoundRecordingSearchQueryVariables {
    queryId?: number;
}

export type GlobalSoundRecordingSearchResult = [
    (variables: Variables) => void,
    {
        loading: boolean;
        called: boolean;
        error?: ApolloError;
        songs: GlobalSoundRecordingSearchItems;
    },
];

export const useLazyGlobalSoundRecordingSearch =
    (): GlobalSoundRecordingSearchResult => {
        const queryId = useRef(0);
        const abortController = useRef<AbortController>();
        if (!abortController.current)
            abortController.current = new AbortController();
        const { signal } = abortController.current;

        const [doCatalogWriterSearch, catalogResult] =
            useLazyQuery<GlobalSoundRecordingSearchQuery>(
                GlobalSoundRecordingSearchDocument,
                { context: { fetchOptions: { signal } } }
            );

        const songsData =
            catalogResult?.data?.globalSoundRecordingSearchES.items || [];

        return [
            variables => {
                abortController.current?.abort();
                abortController.current = new AbortController();

                if (variables.term.length > 0)
                    void doCatalogWriterSearch({
                        variables: { queryId: queryId.current++, ...variables },
                    });
            },
            { ...catalogResult, songs: songsData },
        ];
    };
