import { useRef } from 'react';
import { useLazyQuery } from '@apollo/client';
import { LabelParticipantSearchDocument } from 'src/data/queries/labelParticipantSearch/__generated__/labelParticipantSearch';
import type { ApolloError } from '@apollo/client';
import type {
    LabelParticipantSearchQuery,
    LabelParticipantSearchQueryVariables,
} from 'src/data/queries/labelParticipantSearch/__generated__/labelParticipantSearch';
import type { LabelParticipantSearchItems } from 'src/types';

interface Variables extends LabelParticipantSearchQueryVariables {
    queryId?: number;
}

type LabelParticipantSearchResult = [
    (variables: Variables) => void,
    {
        loading: boolean;
        called: boolean;
        error?: ApolloError;
        participants: LabelParticipantSearchItems;
    },
];

export const useLazyLabelParticipantSearch =
    (): LabelParticipantSearchResult => {
        const queryId = useRef(0);
        const abortController = useRef<AbortController>();
        if (!abortController.current)
            abortController.current = new AbortController();

        const { signal } = abortController.current;

        const [doLabelParticipantSearch, labelParticipantResult] =
            useLazyQuery<LabelParticipantSearchQuery>(
                LabelParticipantSearchDocument,
                { context: { fetchOptions: { signal } } }
            );

        const participants =
            labelParticipantResult.data?.activeProfile.labelParticipantSearchV2
                .items || [];

        return [
            (variables: Variables) => {
                abortController.current?.abort();
                abortController.current = new AbortController();

                if (variables.term.length)
                    void doLabelParticipantSearch({
                        variables: {
                            ...variables,
                            queryId: ++queryId.current,
                        },
                    });
            },
            { ...labelParticipantResult, participants },
        ];
    };
