import { useRef } from 'react';
import { useLazyQuery } from '@apollo/client';
import {
    generateQuery,
    SPOTIFY_LIMIT,
} from 'src/data/queries/spotifyTrackSearch/spotifyTrackSearchQueryGenerator';
import type { ApolloError } from '@apollo/client';
import type {
    SpotifyTrackSearchQuery,
    SpotifyTrackSearchQueryVariables,
} from 'src/data/queries/spotifyTrackSearch/__generated__/spotifyTrackSearch';
import type { SpotifyTrackSearchItems } from 'src/types';

export type SpotifyTrackSearchResult = [
    (variables: SpotifyTrackSearchQueryVariables) => void,
    {
        loading: boolean;
        called: boolean;
        error?: ApolloError;
        data: SpotifyTrackSearchItems;
    },
];

export const useLazySpotifyTrackSearch = (): SpotifyTrackSearchResult => {
    const limitRef = useRef<number>();
    const abortController = useRef<AbortController>();
    if (!abortController.current)
        abortController.current = new AbortController();
    const { signal } = abortController.current;

    const [doSpotifyTrackSearch, spotifyResult] =
        useLazyQuery<SpotifyTrackSearchQuery>(generateQuery(limitRef.current), {
            context: { fetchOptions: { signal } },
        });
    const catalogData: SpotifyTrackSearchItems = [];
    if (spotifyResult?.data)
        Object.keys(spotifyResult?.data).forEach(key => {
            const data: { items: SpotifyTrackSearchItems } =
                // eslint-disable-next-line
                // @ts-ignore some weired situation with typing here
                spotifyResult.data[key];
            catalogData.push(...data.items);
        });

    return [
        ({ term, limit }) => {
            abortController.current?.abort();
            abortController.current = new AbortController();

            limitRef.current = limit || 0;

            if (term.length > 0)
                void doSpotifyTrackSearch({
                    variables: {
                        term,
                        limit:
                            limit && limit < SPOTIFY_LIMIT
                                ? limit
                                : SPOTIFY_LIMIT,
                    },
                });
        },
        { ...spotifyResult, data: catalogData },
    ];
};
