import type { FC } from 'react';
import React, { useEffect, useState } from 'react';
import { Field, Form } from '@theorchard/suite-components';
import { formatMessage } from '@theorchard/suite-frontend';
import { CloseGlyph } from '@theorchard/suite-icons';
import PublisherAsyncSelect from 'src/components/publisherAsyncSelect';
import type { ControlledPublisher } from 'src/components/editSongWriter/publishersComponent';
import type { Label, Publisher } from 'src/types';

interface Props {
    label: Label;
    excludePublisherIds: string[];
    onUpdatePublisher: (p: ControlledPublisher | null) => void;
    onDeleteNewPublisher: () => void;
}

const AddPublisherComponent: FC<Props> = ({
    label,
    onUpdatePublisher,
    excludePublisherIds,
    onDeleteNewPublisher,
}) => {
    const [publisher, setPublisher] = useState<Publisher | null>(null);
    const [controlled, setControlled] = useState<boolean>(false);

    useEffect(() => {
        onUpdatePublisher(
            publisher
                ? {
                      ...publisher,
                      controlled,
                  }
                : null
        );
    }, [publisher, controlled, onUpdatePublisher]);

    return (
        <div className="AddPublisherComponent">
            <div className="header">
                <PublisherAsyncSelect
                    label={label}
                    onChange={p => setPublisher(p || null)}
                    withAddNewOption={false}
                    excludeFilter={{ publisherIds: excludePublisherIds }}
                />
                <div className="deletePublisher" onClick={onDeleteNewPublisher}>
                    <CloseGlyph />
                </div>
            </div>

            <Field
                controlId="isControlled"
                labelText={formatMessage('songWriterManager.isControlled')}
                isRequired
            >
                <Form.Check
                    checked={controlled}
                    onChange={() => setControlled(true)}
                    name="isNewCompanyControlled"
                    label={formatMessage('generic.yes')}
                    type="radio"
                    id="isNewCompanyControlled1"
                />
                <Form.Check
                    checked={!controlled}
                    onChange={() => setControlled(false)}
                    name="isNewCompanyControlled"
                    label={formatMessage('generic.no')}
                    type="radio"
                    id="isNewCompanyControlled1"
                />
            </Field>
        </div>
    );
};

export default AddPublisherComponent;
