/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

import * as Types from 'src/data/schemaTypes';

import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type GlobalSoundRecordingSearchQueryVariables = Types.Exact<{
    term: Types.Scalars['String']['input'];
}>;

export type GlobalSoundRecordingSearchQuery = {
    __typename?: 'Query';
    globalSoundRecordingSearchES: {
        __typename?: 'GlobalSoundRecordingSearchResult';
        items: Array<{
            __typename?: 'GlobalSoundRecordingSearchResultScoredItem';
            item: {
                __typename?: 'GlobalSoundRecordingSearchResultItem';
                id: string;
                name: string;
                isrc: string;
                releaseDate?: string | null;
                imageUrl?: string | null;
                usageStats: {
                    __typename?: 'GlobalSoundRecordingSearchResultUsageStats';
                    streamsAllTime?: number | null;
                };
                globalSoundRecording: {
                    __typename?: 'GlobalSoundRecording';
                    labelSoundRecordings: Array<{
                        __typename?: 'LabelSoundRecording';
                        tracks: Array<{
                            __typename?: 'Track';
                            tuid: string;
                            trackName?: string | null;
                            performers?: Array<{
                                __typename?: 'Performer';
                                name: string;
                            } | null> | null;
                            writers?: Array<{
                                __typename?: 'TrackWriter';
                                name?: string | null;
                            } | null> | null;
                            product?: {
                                __typename?: 'Product';
                                productName: string;
                                upc?: string | null;
                                releaseDate?: string | null;
                            } | null;
                        }>;
                    }>;
                    publicSoundRecordings: Array<{
                        __typename?: 'PublicSoundRecording';
                        imageUrl?: string | null;
                        participants: Array<{
                            __typename?: 'PublicParticipant';
                            name?: string | null;
                        }>;
                        tracks: Array<{
                            __typename?: 'PublicTrack';
                            product?: {
                                __typename?: 'PublicProduct';
                                name?: string | null;
                                upc?: string | null;
                            } | null;
                        }>;
                    }>;
                };
            };
        }>;
    };
};

export const GlobalSoundRecordingSearchDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'query',
            name: { kind: 'Name', value: 'GlobalSoundRecordingSearch' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'term' },
                    },
                    type: {
                        kind: 'NonNullType',
                        type: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'String' },
                        },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: {
                            kind: 'Name',
                            value: 'globalSoundRecordingSearchES',
                        },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'term' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'term' },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'items' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'item',
                                                },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'id',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'name',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'isrc',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'releaseDate',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'imageUrl',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'usageStats',
                                                            },
                                                            selectionSet: {
                                                                kind: 'SelectionSet',
                                                                selections: [
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'streamsAllTime',
                                                                        },
                                                                    },
                                                                ],
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'globalSoundRecording',
                                                            },
                                                            selectionSet: {
                                                                kind: 'SelectionSet',
                                                                selections: [
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'labelSoundRecordings',
                                                                        },
                                                                        selectionSet:
                                                                            {
                                                                                kind: 'SelectionSet',
                                                                                selections:
                                                                                    [
                                                                                        {
                                                                                            kind: 'Field',
                                                                                            name: {
                                                                                                kind: 'Name',
                                                                                                value: 'tracks',
                                                                                            },
                                                                                            selectionSet:
                                                                                                {
                                                                                                    kind: 'SelectionSet',
                                                                                                    selections:
                                                                                                        [
                                                                                                            {
                                                                                                                kind: 'Field',
                                                                                                                name: {
                                                                                                                    kind: 'Name',
                                                                                                                    value: 'tuid',
                                                                                                                },
                                                                                                            },
                                                                                                            {
                                                                                                                kind: 'Field',
                                                                                                                name: {
                                                                                                                    kind: 'Name',
                                                                                                                    value: 'trackName',
                                                                                                                },
                                                                                                            },
                                                                                                            {
                                                                                                                kind: 'Field',
                                                                                                                name: {
                                                                                                                    kind: 'Name',
                                                                                                                    value: 'performers',
                                                                                                                },
                                                                                                                selectionSet:
                                                                                                                    {
                                                                                                                        kind: 'SelectionSet',
                                                                                                                        selections:
                                                                                                                            [
                                                                                                                                {
                                                                                                                                    kind: 'Field',
                                                                                                                                    name: {
                                                                                                                                        kind: 'Name',
                                                                                                                                        value: 'name',
                                                                                                                                    },
                                                                                                                                },
                                                                                                                            ],
                                                                                                                    },
                                                                                                            },
                                                                                                            {
                                                                                                                kind: 'Field',
                                                                                                                name: {
                                                                                                                    kind: 'Name',
                                                                                                                    value: 'writers',
                                                                                                                },
                                                                                                                selectionSet:
                                                                                                                    {
                                                                                                                        kind: 'SelectionSet',
                                                                                                                        selections:
                                                                                                                            [
                                                                                                                                {
                                                                                                                                    kind: 'Field',
                                                                                                                                    name: {
                                                                                                                                        kind: 'Name',
                                                                                                                                        value: 'name',
                                                                                                                                    },
                                                                                                                                },
                                                                                                                            ],
                                                                                                                    },
                                                                                                            },
                                                                                                            {
                                                                                                                kind: 'Field',
                                                                                                                name: {
                                                                                                                    kind: 'Name',
                                                                                                                    value: 'product',
                                                                                                                },
                                                                                                                selectionSet:
                                                                                                                    {
                                                                                                                        kind: 'SelectionSet',
                                                                                                                        selections:
                                                                                                                            [
                                                                                                                                {
                                                                                                                                    kind: 'Field',
                                                                                                                                    name: {
                                                                                                                                        kind: 'Name',
                                                                                                                                        value: 'productName',
                                                                                                                                    },
                                                                                                                                },
                                                                                                                                {
                                                                                                                                    kind: 'Field',
                                                                                                                                    name: {
                                                                                                                                        kind: 'Name',
                                                                                                                                        value: 'upc',
                                                                                                                                    },
                                                                                                                                },
                                                                                                                                {
                                                                                                                                    kind: 'Field',
                                                                                                                                    name: {
                                                                                                                                        kind: 'Name',
                                                                                                                                        value: 'releaseDate',
                                                                                                                                    },
                                                                                                                                },
                                                                                                                            ],
                                                                                                                    },
                                                                                                            },
                                                                                                        ],
                                                                                                },
                                                                                        },
                                                                                    ],
                                                                            },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'publicSoundRecordings',
                                                                        },
                                                                        selectionSet:
                                                                            {
                                                                                kind: 'SelectionSet',
                                                                                selections:
                                                                                    [
                                                                                        {
                                                                                            kind: 'Field',
                                                                                            name: {
                                                                                                kind: 'Name',
                                                                                                value: 'imageUrl',
                                                                                            },
                                                                                        },
                                                                                        {
                                                                                            kind: 'Field',
                                                                                            name: {
                                                                                                kind: 'Name',
                                                                                                value: 'participants',
                                                                                            },
                                                                                            selectionSet:
                                                                                                {
                                                                                                    kind: 'SelectionSet',
                                                                                                    selections:
                                                                                                        [
                                                                                                            {
                                                                                                                kind: 'Field',
                                                                                                                name: {
                                                                                                                    kind: 'Name',
                                                                                                                    value: 'name',
                                                                                                                },
                                                                                                            },
                                                                                                        ],
                                                                                                },
                                                                                        },
                                                                                        {
                                                                                            kind: 'Field',
                                                                                            name: {
                                                                                                kind: 'Name',
                                                                                                value: 'tracks',
                                                                                            },
                                                                                            selectionSet:
                                                                                                {
                                                                                                    kind: 'SelectionSet',
                                                                                                    selections:
                                                                                                        [
                                                                                                            {
                                                                                                                kind: 'Field',
                                                                                                                name: {
                                                                                                                    kind: 'Name',
                                                                                                                    value: 'product',
                                                                                                                },
                                                                                                                selectionSet:
                                                                                                                    {
                                                                                                                        kind: 'SelectionSet',
                                                                                                                        selections:
                                                                                                                            [
                                                                                                                                {
                                                                                                                                    kind: 'Field',
                                                                                                                                    name: {
                                                                                                                                        kind: 'Name',
                                                                                                                                        value: 'name',
                                                                                                                                    },
                                                                                                                                },
                                                                                                                                {
                                                                                                                                    kind: 'Field',
                                                                                                                                    name: {
                                                                                                                                        kind: 'Name',
                                                                                                                                        value: 'upc',
                                                                                                                                    },
                                                                                                                                },
                                                                                                                            ],
                                                                                                                    },
                                                                                                            },
                                                                                                        ],
                                                                                                },
                                                                                        },
                                                                                    ],
                                                                            },
                                                                    },
                                                                ],
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    GlobalSoundRecordingSearchQuery,
    GlobalSoundRecordingSearchQueryVariables
>;
