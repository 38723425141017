import { useQuery } from '@apollo/client';
import { PublishingPublishersDocument } from 'src/data/queries/publishers/__generated__/publishers';
import { PUBLISHERS_PER_PAGE } from 'src/pages/publishersListPage/publishersListPage';
import type { ApolloQueryResult, OperationVariables } from '@apollo/client';
import type { PublishingPublishersQuery } from 'src/data/queries/publishers/__generated__/publishers';
import type { PublishingPublishersFilter } from 'src/data/schemaTypes';
import type { Label } from 'src/types';

export interface PublishingPublishersFilterLabelInput
    extends PublishingPublishersFilter {
    label?: Label | null;
}

const defaultLabelFilter = { label: null };

const makeRefetch =
    (
        refetch: (
            variables?: Partial<OperationVariables> | undefined
        ) => Promise<ApolloQueryResult<PublishingPublishersQuery>>,
        limit: number
    ) =>
    async (
        filter: PublishingPublishersFilterLabelInput = defaultLabelFilter,
        page = 1
    ) => {
        const uuid = filter?.label?.uuid;
        return await refetch({
            filter: {
                ...filter,
                label: uuid ? { uuid } : null,
            },
            limit,
            offset: (page - 1) * limit,
        });
    };

export const usePublishingPublishers = (
    filter: PublishingPublishersFilterLabelInput = defaultLabelFilter,
    page = 1,
    limit = PUBLISHERS_PER_PAGE
) => {
    const offset = (page - 1) * limit;
    const uuid = filter?.label?.uuid;
    const { error, loading, data, refetch, networkStatus } =
        useQuery<PublishingPublishersQuery>(PublishingPublishersDocument, {
            notifyOnNetworkStatusChange: true,
            variables: {
                filter: {
                    ...filter,
                    label: uuid ? { uuid } : null,
                },
                limit,
                offset,
            },
        });

    const finalData = data?.publishingPublishers?.publishers;

    return {
        error,
        loading,
        data: {
            totalCount: data?.publishingPublishers?.totalCount,
            publishers: finalData,
        },
        refetch: makeRefetch(refetch, limit),
        networkStatus,
    };
};
