import React from 'react';
import {
    BRAND_AWAL,
    formatMessage,
    useIdentity,
} from '@theorchard/suite-frontend';
import { SearchResultsOutlineIcon } from '@theorchard/suite-icons';

interface ReadErrorProps {
    error?: {
        message: string;
    };
}

const ReadError: React.FC<ReadErrorProps> = ({ error }) => {
    const { defaultBrand } = useIdentity();
    React.useEffect(() => {
        if (error && error.message)
            // eslint-disable-next-line no-console
            console.log(error.message);
    }, [error]);

    return (
        <div className="ReadError">
            <SearchResultsOutlineIcon />
            <div className="ReadError-title">
                {formatMessage('errors.somethingWrong')}
            </div>
            <div className="ReadError-title">
                {formatMessage('errors.couldNotFind')}
            </div>

            <div className="ReadError-subtitle">
                {formatMessage('errors.tryAgain', {
                    brand: defaultBrand === BRAND_AWAL ? 'awal' : 'theorchard',
                })}
            </div>
        </div>
    );
};

export default ReadError;
