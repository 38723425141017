import React from 'react';
import { Sidecar } from '@theorchard/suite-components';
import { formatMessage, useIdentity } from '@theorchard/suite-frontend';
import { flatten } from 'lodash';
import { useHistory } from 'react-router-dom';
import { FEATURE_FLAGS } from 'src/constants';
import { songUrl } from 'src/utils/urls';
import type { CompositionExtended as Composition, SongWriter } from 'src/types';

interface Props {
    isOpen: boolean;
    onClose: () => void;
    songWriter: SongWriter | null;
}

const CompositionLink: React.FC<{ composition: Composition }> = ({
    composition,
}) => {
    const history = useHistory();

    return (
        <div
            className="ViewSongWriter-link"
            key={composition.id}
            onClick={() => history.push(songUrl(composition.id))}
        >
            {composition.title}
        </div>
    );
};

const SplitCompositionsBlock: React.FC<{
    controlledCompositions: Composition[];
    uncontrolledCompositions: Composition[];
}> = ({ controlledCompositions, uncontrolledCompositions }) => (
    <>
        {!!controlledCompositions.length && (
            <>
                <div className="ViewSongWriter-label">
                    {formatMessage('generic.controlled')}
                </div>
                {controlledCompositions.map(composition => (
                    <CompositionLink
                        key={composition.id}
                        composition={composition}
                    />
                ))}
            </>
        )}
        {!!uncontrolledCompositions.length && (
            <>
                <div className="ViewSongWriter-label">
                    {formatMessage('generic.uncontrolled')}
                </div>
                {uncontrolledCompositions.map(composition => (
                    <CompositionLink
                        key={composition.id}
                        composition={composition}
                    />
                ))}
            </>
        )}
    </>
);

const ViewSongWriter: React.FC<Props> = ({ isOpen, onClose, songWriter }) => {
    const identity = useIdentity();
    const isSplitSongWriter =
        identity.features[FEATURE_FLAGS.ALLOW_SPLIT_BOTH_SONGWRITER];

    const compositions =
        (songWriter?.compositions?.slice().sort((a, b) => {
            if (a.title > b.title) return 1;
            if (a.title < b.title) return -1;
            return 0;
        }) as Composition[]) ?? null;

    const controlledCompositions =
        flatten(
            songWriter?.agreements
                .filter(a => a.controlled)
                .map(a => a.compositions as Composition[])
        ) || [];
    const uncontrolledCompositions =
        flatten(
            songWriter?.agreements
                .filter(a => !a.controlled)
                .map(a => a.compositions as Composition[])
        ) || [];

    const hasCompositions =
        (songWriter?.compositions && songWriter.compositions.length) ||
        controlledCompositions.length ||
        uncontrolledCompositions.length;

    return (
        <Sidecar
            isOpen={isOpen}
            onRequestClose={onClose}
            className="ViewSongWriter"
            header={formatMessage('viewSongWriter.title')}
        >
            <div key={`main-${songWriter?.id}`}>
                <div>
                    <div className="ViewSongWriter-label">
                        {formatMessage('songWriterManager.songWriterLabelName')}
                    </div>
                    <div className="ViewSongWriter-value">
                        {songWriter?.vendor.name} -{' '}
                        {songWriter?.vendor.id.vendorId}
                    </div>
                </div>
                <div>
                    <div className="ViewSongWriter-label">
                        {formatMessage('songWriterManager.songWriterLegalName')}
                    </div>
                    <div className="ViewSongWriter-value">
                        {songWriter?.legalName}
                    </div>
                </div>

                {songWriter?.pro && (
                    <div>
                        <div className="ViewSongWriter-label">
                            {formatMessage('songWriterManager.songwritersPro')}
                        </div>
                        <div className="ViewSongWriter-value">
                            {songWriter?.pro}
                        </div>
                    </div>
                )}

                {songWriter?.ipi && (
                    <div>
                        <div className="ViewSongWriter-label">
                            {formatMessage('songWriterManager.songwritersIpi')}
                        </div>
                        <div className="ViewSongWriter-value">
                            {songWriter?.ipi}
                        </div>
                    </div>
                )}

                {songWriter?.agreements.map(agreement => (
                    <div key={agreement.id}>
                        {agreement.publisher?.name && (
                            <div>
                                <div className="ViewSongWriter-label">
                                    {formatMessage('generic.publishingCompany')}
                                </div>
                                <div className="ViewSongWriter-value">
                                    {agreement.publisher?.name}
                                </div>
                            </div>
                        )}

                        {agreement.publisher?.pro && (
                            <div>
                                <div className="ViewSongWriter-label">
                                    {formatMessage(
                                        'generic.publishingCompanyPro'
                                    )}
                                </div>
                                <div className="ViewSongWriter-value">
                                    {agreement.publisher?.pro}
                                </div>
                            </div>
                        )}

                        {agreement.publisher?.ipi && (
                            <div>
                                <div className="ViewSongWriter-label">
                                    {formatMessage(
                                        'generic.publishingCompanyIpi'
                                    )}
                                </div>
                                <div className="ViewSongWriter-value">
                                    {agreement.publisher?.ipi}
                                </div>
                            </div>
                        )}
                    </div>
                ))}

                {!!hasCompositions && (
                    <div className="ViewSongWriter-header">
                        {formatMessage('generic.songs')}
                    </div>
                )}

                {isSplitSongWriter ? (
                    <SplitCompositionsBlock
                        controlledCompositions={controlledCompositions}
                        uncontrolledCompositions={uncontrolledCompositions}
                    />
                ) : (
                    compositions?.map(composition => (
                        <CompositionLink
                            key={composition.id}
                            composition={composition}
                        />
                    ))
                )}
            </div>
        </Sidecar>
    );
};

export default ViewSongWriter;
