/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

import * as Types from 'src/data/schemaTypes';

import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type PublishingCompositionsQueryVariables = Types.Exact<{
    filter?: Types.InputMaybe<Types.PublishingCompositionsFilter>;
    limit: Types.Scalars['Int']['input'];
    offset: Types.Scalars['Int']['input'];
}>;

export type PublishingCompositionsQuery = {
    __typename?: 'Query';
    publishingCompositions: {
        __typename?: 'PublishingCompositionsResults';
        totalCount: number;
        compositions: Array<{
            __typename?: 'PublishingComposition';
            id: string;
            title: string;
            draft: boolean;
            createdAt: string;
            submittedAt?: string | null;
            modifiedAt?: string | null;
            pubSongId: number;
            associatedGlobalSoundRecordings: Array<{
                __typename?: 'GlobalSoundRecording';
                id: string;
            }>;
            associatedLabelSoundRecordings: Array<{
                __typename?: 'LabelSoundRecording';
                id: string;
            }>;
            agreements: Array<{
                __typename?: 'PublishingCompositionAgreement';
                hasLyricsContribution: boolean;
                hasMusicContribution: boolean;
                hasArrangerContribution: boolean;
                split: string;
                agreement: {
                    __typename?: 'PublishingAgreement';
                    id: string;
                    controlled: boolean;
                    publisher?: {
                        __typename?: 'PublishingPublisher';
                        id: string;
                        ipi?: string | null;
                        name?: string | null;
                        pro?: string | null;
                    } | null;
                    songWriter: {
                        __typename?: 'PublishingSongWriter';
                        id: string;
                        ipi?: string | null;
                        legalName: string;
                        pro?: string | null;
                    };
                };
            }>;
        }>;
    };
};

export const PublishingCompositionsDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'query',
            name: { kind: 'Name', value: 'PublishingCompositions' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'filter' },
                    },
                    type: {
                        kind: 'NamedType',
                        name: {
                            kind: 'Name',
                            value: 'PublishingCompositionsFilter',
                        },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'limit' },
                    },
                    type: {
                        kind: 'NonNullType',
                        type: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'Int' },
                        },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'offset' },
                    },
                    type: {
                        kind: 'NonNullType',
                        type: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'Int' },
                        },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'publishingCompositions' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'filter' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'filter' },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'limit' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'limit' },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'offset' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'offset' },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: {
                                        kind: 'Name',
                                        value: 'compositions',
                                    },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'id',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'title',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'draft',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'associatedGlobalSoundRecordings',
                                                },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'id',
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'associatedLabelSoundRecordings',
                                                },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'id',
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'createdAt',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'submittedAt',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'modifiedAt',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'pubSongId',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'agreements',
                                                },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'agreement',
                                                            },
                                                            selectionSet: {
                                                                kind: 'SelectionSet',
                                                                selections: [
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'id',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'controlled',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'publisher',
                                                                        },
                                                                        selectionSet:
                                                                            {
                                                                                kind: 'SelectionSet',
                                                                                selections:
                                                                                    [
                                                                                        {
                                                                                            kind: 'Field',
                                                                                            name: {
                                                                                                kind: 'Name',
                                                                                                value: 'id',
                                                                                            },
                                                                                        },
                                                                                        {
                                                                                            kind: 'Field',
                                                                                            name: {
                                                                                                kind: 'Name',
                                                                                                value: 'ipi',
                                                                                            },
                                                                                        },
                                                                                        {
                                                                                            kind: 'Field',
                                                                                            name: {
                                                                                                kind: 'Name',
                                                                                                value: 'name',
                                                                                            },
                                                                                        },
                                                                                        {
                                                                                            kind: 'Field',
                                                                                            name: {
                                                                                                kind: 'Name',
                                                                                                value: 'pro',
                                                                                            },
                                                                                        },
                                                                                    ],
                                                                            },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'songWriter',
                                                                        },
                                                                        selectionSet:
                                                                            {
                                                                                kind: 'SelectionSet',
                                                                                selections:
                                                                                    [
                                                                                        {
                                                                                            kind: 'Field',
                                                                                            name: {
                                                                                                kind: 'Name',
                                                                                                value: 'id',
                                                                                            },
                                                                                        },
                                                                                        {
                                                                                            kind: 'Field',
                                                                                            name: {
                                                                                                kind: 'Name',
                                                                                                value: 'ipi',
                                                                                            },
                                                                                        },
                                                                                        {
                                                                                            kind: 'Field',
                                                                                            name: {
                                                                                                kind: 'Name',
                                                                                                value: 'legalName',
                                                                                            },
                                                                                        },
                                                                                        {
                                                                                            kind: 'Field',
                                                                                            name: {
                                                                                                kind: 'Name',
                                                                                                value: 'pro',
                                                                                            },
                                                                                        },
                                                                                    ],
                                                                            },
                                                                    },
                                                                ],
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'hasLyricsContribution',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'hasMusicContribution',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'hasArrangerContribution',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'split',
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'totalCount' },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    PublishingCompositionsQuery,
    PublishingCompositionsQueryVariables
>;
