import { useEffect, useState } from 'react';
import { Decimal } from 'decimal.js';
import { useLazyIsrcEntities } from 'src/data/queries';
import { getSum } from 'src/utils/helpers';
import type {
    AssociatedLabelSoundRecording,
    AssociatedSoundRecording,
    Composition,
    GlobalSoundRecording,
    LabelSoundRecording,
} from 'src/types';

export const isCompletelyOwned = (decimals: Decimal[]) => {
    const total = getSum(decimals);
    return total.equals(new Decimal(100));
};

export const songPageHeader = (composition: Composition | null) => {
    if (!composition || composition?.draft)
        return 'selectExistingSong.draftSong';
    return 'selectExistingSong.submittedSong';
};

export const gsrToAssociatedSoundRecording = (
    gsr: GlobalSoundRecording
): AssociatedSoundRecording => ({
    id: gsr.id,
    isrc: gsr.isrc,
    name: gsr.name,
    performers: gsr.globalParticipants?.map(p => p?.name ?? null) ?? [],
    writers: [],
    isLsr: false,
});

export const lsrToAssociatedSoundRecording = (
    lsr: LabelSoundRecording
): AssociatedSoundRecording => ({
    id: lsr.id,
    isrc: lsr.isrc,
    name: lsr.name || null,
    performers: [
        ...(lsr.tracks[0]?.primaryArtists
            ? (lsr.tracks[0]?.primaryArtists || []).map(
                  p => p?.artistName || ''
              )
            : []),
        ...(lsr.participations
            ? (lsr.participations || []).map(p => p?.participant.name || '')
            : []),
    ],
    writers: lsr.tracks[0]?.writers
        ? lsr.tracks[0]?.writers?.map(w => w?.name || '')
        : [],
    isLsr: true,
});

export const pclsrToAssociatedSoundRecording = (
    lsr: AssociatedLabelSoundRecording
): AssociatedSoundRecording => ({
    id: lsr.id,
    isrc: lsr.isrc,
    name: lsr.name || '',
    performers: lsr.tracks[0]?.primaryArtists
        ? lsr.tracks[0]?.primaryArtists?.map(p => p?.artistName || '')
        : [],
    writers: lsr.tracks[0]?.writers
        ? lsr.tracks[0]?.writers?.map(w => w?.name || '')
        : [],
    isLsr: true,
});

export const useGetClashedCompositions = (
    currentCompositionId: string | null, // value is null for new compositions; of type Composition for submitted/draft compositions
    associatedRecordings: AssociatedSoundRecording[], // list of composition's associated recordings
    vendorId?: number // vendor which has this composition
): string | null => {
    const [clashedComposition, setClashedComposition] = useState<{
        id: string | null;
        isrc: string | null;
    }>({ id: null, isrc: null });

    const [doIsrcEntitiesSearch, { entities }] = useLazyIsrcEntities();

    useEffect(() => {
        associatedRecordings.map(a => doIsrcEntitiesSearch({ isrc: a.isrc }));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [associatedRecordings]);

    useEffect(() => {
        const searchedIsrc = associatedRecordings.find(
            asr => entities?.isrc === asr.isrc
        );
        const compositionWithSameIsrcVendor =
            entities?.compositions.compositions.find(
                c => c.vendor.vendorId === vendorId
            );
        const compositionExclCurrent =
            entities?.compositions.compositions.find(
                c =>
                    currentCompositionId !== c.id &&
                    c.vendor.vendorId === vendorId
            ) ?? null;
        const clashingComposition = associatedRecordings.find(
            asr => clashedComposition.isrc === asr.isrc
        );

        if (
            searchedIsrc &&
            compositionWithSameIsrcVendor &&
            compositionExclCurrent
        )
            setClashedComposition({
                id: compositionWithSameIsrcVendor.id,
                isrc: searchedIsrc.isrc,
            });

        if (!compositionWithSameIsrcVendor && !clashingComposition)
            setClashedComposition({ id: null, isrc: null });

        if (!associatedRecordings.length)
            setClashedComposition({ id: null, isrc: null });
    }, [
        entities,
        associatedRecordings,
        vendorId,
        currentCompositionId,
        clashedComposition.isrc,
    ]);

    return clashedComposition.id;
};
