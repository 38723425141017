import { useApolloClient, useMutation } from '@apollo/client';
import { PublishingUpdatePublisherDocument } from 'src/data/mutations/updatePublisher/__generated__/updatePublisher';
import type { PublishingUpdatePublisherMutation } from 'src/data/mutations/updatePublisher/__generated__/updatePublisher';
import type { MutationSuccessCB } from 'src/types';

export const useUpdatePublisher = (onUpdatePublisherCB: MutationSuccessCB) => {
    const client = useApolloClient();

    const [mutation] = useMutation(PublishingUpdatePublisherDocument, {
        update() {
            // eslint-disable-next-line @typescript-eslint/no-floating-promises
            client.resetStore();
        },
        onCompleted: (data: PublishingUpdatePublisherMutation) =>
            onUpdatePublisherCB(data),
        onError: data => {
            const errors = data.graphQLErrors
                ?.map(e =>
                    e.extensions
                        ? Object.keys(e.extensions.validationErrors)
                        : ''
                )
                ?.filter(i => i);
            onUpdatePublisherCB(null, errors.flat());
        },
    });

    return mutation;
};
