import React from 'react';
import { Col, InputGroup, Row, Form } from '@theorchard/suite-components';
import { formatMessage } from '@theorchard/suite-frontend';
import { SearchGlyph } from '@theorchard/suite-icons';
import BrandSelector from 'src/components/brandSelector';
import { CLASSNAME } from './songWriters';
import type { BrandOption } from 'src/components/brandSelector';
import type { CompanyBrandName } from 'src/data/schemaTypes';

interface Props {
    onSearchChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
    isEmployeeWithFullAccess: boolean;
    initialBrandValue?: CompanyBrandName;
    onBrandChange: (value?: BrandOption) => void;
}

const PageControls: React.FC<Props> = ({
    onSearchChange,
    isEmployeeWithFullAccess,
    initialBrandValue,
    onBrandChange,
}) => (
    <div className="PageControls mb-3">
        <Row>
            <Col xs={12} md={6} lg={4}>
                <InputGroup>
                    <InputGroup.Prepend>
                        <InputGroup.Text>
                            <SearchGlyph />
                        </InputGroup.Text>
                    </InputGroup.Prepend>
                    <Form.Control
                        type="text"
                        placeholder={formatMessage(
                            `${CLASSNAME}.searchPlaceholder`
                        )}
                        onChange={onSearchChange}
                    />
                </InputGroup>
            </Col>
            {isEmployeeWithFullAccess && (
                <Col xs={6} md={3} lg={2}>
                    <BrandSelector
                        initialValue={initialBrandValue}
                        onChange={onBrandChange}
                    />
                </Col>
            )}
        </Row>
    </div>
);

export default PageControls;
