import { useMutation, useApolloClient } from '@apollo/client';
import { PublishingGlobalSoundRecordingFromSpotifyDocument } from 'src/data/mutations/createGlobalSoundRecordingFromSpotify/__generated__/createGlobalSoundRecordingFromSpotify';
import type { PublishingGlobalSoundRecordingFromSpotifyMutation } from 'src/data/mutations/createGlobalSoundRecordingFromSpotify/__generated__/createGlobalSoundRecordingFromSpotify';

export const useCreateGlobalSoundRecordingFromSpotify = (
    onCompleted: (
        data: PublishingGlobalSoundRecordingFromSpotifyMutation
    ) => void
) => {
    const client = useApolloClient();

    const [mutation] = useMutation(
        PublishingGlobalSoundRecordingFromSpotifyDocument,
        {
            update() {
                // eslint-disable-next-line @typescript-eslint/no-floating-promises
                client.resetStore();
            },
            onCompleted,
        }
    );
    return mutation;
};
