import React from 'react';
import { CloseGlyph } from '@theorchard/suite-icons';
import type { AssociatedSoundRecording as AssociatedSoundRecordingType } from 'src/types';

interface Props {
    associatedSound: AssociatedSoundRecordingType;
    remove: () => void;
}

const AssociatedSoundRecording: React.FC<Props> = ({
    associatedSound,
    remove,
}) => {
    const participants = new Set();
    associatedSound.performers?.forEach(p => participants.add(p));
    associatedSound.writers?.forEach(w => participants.add(w));
    return (
        <div className="NewSong-sound-recording">
            <div>{associatedSound.name}</div>
            <div>{Array.from(participants).join(', ')}</div>
            <div>{associatedSound.isrc}</div>
            <div className="NewSong-sound-recording-remove" onClick={remove}>
                <CloseGlyph />
            </div>
        </div>
    );
};

export default AssociatedSoundRecording;
