/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

import * as Types from 'src/data/schemaTypes';

import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type OrchardLabelsQueryVariables = Types.Exact<{ [key: string]: never }>;

export type OrchardLabelsQuery = {
    __typename?: 'Query';
    orchardLabels: Array<
        | {
              __typename?: 'Subaccount';
              name: string;
              uuid: string;
              id: {
                  __typename?: 'LabelId';
                  vendorId: number;
                  subaccountId: number;
              };
          }
        | {
              __typename?: 'Vendor';
              name: string;
              uuid: string;
              id: {
                  __typename?: 'LabelId';
                  vendorId: number;
                  subaccountId: number;
              };
          }
    >;
};

export const OrchardLabelsDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'query',
            name: { kind: 'Name', value: 'OrchardLabels' },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'orchardLabels' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'limit' },
                                value: { kind: 'IntValue', value: '50' },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'labelType' },
                                value: { kind: 'EnumValue', value: 'VENDOR' },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'name' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'uuid' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'vendorId',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'subaccountId',
                                                },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<OrchardLabelsQuery, OrchardLabelsQueryVariables>;
