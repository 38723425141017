import React, { useContext, useEffect } from 'react';
import type { SuitePlugin } from '@theorchard/suite-frontend';
import type { Label } from 'src/types';

const LOCAL_STORAGE_SELECTED_LABEL = 'selected_label_uuid';

interface ApplicationContext {
    label: Label | null;
    setLabel: (label: Label | null) => void;
    labelAlt: Label | null; // alternative value, which is not store to localStorage
    setLabelAlt: (label: Label | null) => void; // alternative value, which is not store to localStorage
}

const AppContext = React.createContext<ApplicationContext>({
    label: null,
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    setLabel: () => {},
    labelAlt: null,
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    setLabelAlt: () => {},
});

const AppContextProvider: React.FC = ({ children }) => {
    const [label, setLabel] = React.useState<Label | null>(null);
    const [labelAlt, setLabelAlt] = React.useState<Label | null>(null);

    useEffect(() => {
        try {
            const l = window.localStorage.getItem(LOCAL_STORAGE_SELECTED_LABEL);
            if (l) {
                const parsed: Label = JSON.parse(l);
                setLabel(parsed);
            }
        } catch (e) {
            setLabel(null);
        }
    }, []);

    useEffect(() => {
        if (label)
            window.localStorage.setItem(
                LOCAL_STORAGE_SELECTED_LABEL,
                JSON.stringify(label)
            );
    }, [label]);

    const value = {
        label,
        setLabel,
        labelAlt,
        setLabelAlt,
    };
    return <AppContext.Provider value={value}>{children}</AppContext.Provider>;
};

const ApplicationContextProviderPlugin: SuitePlugin = {
    Wrapper: ({ children, loaded }) => {
        if (!loaded) return <>{children}</>;

        return <AppContextProvider>{children}</AppContextProvider>;
    },
};

export const useApplicationContext = () =>
    useContext<ApplicationContext>(AppContext);

export default ApplicationContextProviderPlugin;
