import type { FC } from 'react';
import React from 'react';
import { Pagination, Table } from '@theorchard/suite-components';
import { formatMessage } from '@theorchard/suite-frontend';
import PublisherRow from 'src/pages/publishersListPage/publisherRow';
import type { Publisher } from 'src/types';

interface Props {
    publishers: Publisher[];
    page: number;
    totalPages: number;
    totalCount: number;
    pageSize: number;
    onPaginationChange: (page: number) => void;
    onPublisherView: (publisher: Publisher) => void;
    onPublisherEdit: (publisher: Publisher) => void;
    onPublisherDelete: (publisher: Publisher) => void;
    onSetPageSize: (pageSize: number) => void;
}

const PublishersTable: FC<Props> = ({
    publishers,
    page,
    totalPages,
    totalCount,
    pageSize,
    onPaginationChange,
    onPublisherView,
    onPublisherEdit,
    onPublisherDelete,
    onSetPageSize,
}) => (
    <>
        <Table>
            <thead>
                <tr>
                    <th>{formatMessage('publishers.publisherName')}</th>
                    <th>{formatMessage('generic.pro')}</th>
                    <th>{formatMessage('generic.ipi')}</th>
                    <th>{formatMessage('publishers.associatedSongwriters')}</th>
                    <th />
                </tr>
            </thead>

            <tbody>
                {publishers.map(p => (
                    <PublisherRow
                        key={p.id}
                        publisher={p}
                        onPublisherEdit={onPublisherEdit}
                        onPublisherDelete={onPublisherDelete}
                        onPublisherView={onPublisherView}
                    />
                ))}
            </tbody>
        </Table>

        {totalPages > 1 && (
            <div>
                <Pagination
                    onChange={onPaginationChange}
                    currentPage={page}
                    totalCount={totalCount}
                    pageSize={pageSize}
                    onSetPageSize={onSetPageSize}
                />
            </div>
        )}
    </>
);

export default PublishersTable;
