import { useQuery } from '@apollo/client';
import { PublishingSongWritersDocument } from 'src/data/queries/songWritersSearch/__generated__/songWritersSearch';
import { SONGWRITERS_PER_PAGE } from 'src/pages/songWriterListPage/songWriters';
import type { ApolloQueryResult, OperationVariables } from '@apollo/client';
import type {
    PublishingSongWritersQuery,
    PublishingSongWritersQueryVariables,
} from 'src/data/queries/songWritersSearch/__generated__/songWritersSearch';
import type { PublishingSongWritersFilter } from 'src/data/schemaTypes';
import type { Label, SongWriter, ApolloClientObj } from 'src/types';

export interface PublishingSongWritersFilterLabelInput
    extends Omit<PublishingSongWritersFilter, 'label'> {
    label?: Label | null;
}

const defaultLabelFilter = { label: null };

const makeRefetch =
    (
        refetch: (
            variables?: Partial<OperationVariables> | undefined
        ) => Promise<ApolloQueryResult<PublishingSongWritersQuery>>,
        limit: number
    ) =>
    async (
        filter: PublishingSongWritersFilterLabelInput = defaultLabelFilter,
        page = 1
    ) => {
        const uuid = filter?.label?.uuid;

        return await refetch({
            filter: {
                ...filter,
                label: uuid ? { uuid } : null,
            },
            limit,
            offset: (page - 1) * limit,
        });
    };

export const usePublishingSongWriters = (
    filter: PublishingSongWritersFilterLabelInput = defaultLabelFilter,
    page = 1,
    limit = SONGWRITERS_PER_PAGE
) => {
    const offset = (page - 1) * limit;
    const uuid = filter?.label?.uuid;

    const { error, loading, data, refetch, networkStatus } =
        useQuery<PublishingSongWritersQuery>(PublishingSongWritersDocument, {
            notifyOnNetworkStatusChange: true,
            variables: {
                filter: {
                    ...filter,
                    label: uuid ? { uuid } : null,
                },
                limit,
                offset,
            },
        });

    const songWriters = data?.publishingSongWriters?.songWriters;

    return {
        error,
        loading,
        data: {
            songWriters,
            totalCount: data?.publishingSongWriters?.totalCount,
        },
        refetch: makeRefetch(refetch, limit),
        networkStatus,
    };
};

export const getPromisedSongWriterSearch = async (
    filter: PublishingSongWritersFilterLabelInput = defaultLabelFilter,
    client: ApolloClientObj,
    page = 1,
    limit = 25
): Promise<SongWriter[]> => {
    const uuid = filter?.label?.uuid;

    const variables: PublishingSongWritersQueryVariables = {
        filter: {
            ...filter,
            label: uuid ? { uuid } : null,
        },
        limit,
        offset: (page - 1) * limit,
    };

    const { data } = await client.query<
        PublishingSongWritersQuery,
        PublishingSongWritersQueryVariables
    >({
        query: PublishingSongWritersDocument,
        variables,
    });

    const songWriters = data?.publishingSongWriters?.songWriters || [];

    return songWriters;
};
