import React from 'react';
import { Card, Pagination, Table } from '@theorchard/suite-components';
import { formatMessage } from '@theorchard/suite-frontend';
import EmptyState from 'src/components/emptyState';
import SongRow from 'src/pages/songListPage/songRow';
import type { Compositions } from 'src/types';

interface Props {
    isDraft: boolean;
    isLoading: boolean;
    songsData: {
        compositions: Compositions | undefined;
        totalCount: number | undefined;
    };
    page: number;
    pageSize: number;
    showPubSongId: boolean;
    onClickSong: () => void;
    onEditSong: (id: string) => void;
    onDeleteSong: (id: string) => void;
    onToggleSongRowDropDown: (isOpen: boolean) => void;
    onPaginationChange: (page: number) => void;
    onSetPageSize: (pageSize: number) => void;
}

const SongTable: React.FC<Props> = ({
    isDraft,
    isLoading,
    songsData,
    page,
    pageSize,
    showPubSongId,
    onClickSong,
    onEditSong,
    onDeleteSong,
    onToggleSongRowDropDown,
    onPaginationChange,
    onSetPageSize,
}) => {
    const { compositions, totalCount } = songsData;
    const totalPages = Math.ceil((totalCount || 0) / pageSize);

    return (
        <Card className="p-2">
            {!compositions?.length ? (
                <EmptyState isLoading={isLoading} page="songs" />
            ) : (
                <Table>
                    <thead>
                        <tr>
                            <th>{formatMessage('generic.songTitle')}</th>
                            {showPubSongId && (
                                <th>{formatMessage('generic.songId')}</th>
                            )}
                            <th>{formatMessage('generic.songWriters')}</th>
                            <th>
                                {formatMessage('songs.associatedRecordings')}
                            </th>
                            <th>{formatMessage('generic.ownership')}</th>
                            <th>
                                {isDraft
                                    ? formatMessage('generic.modifiedDate')
                                    : formatMessage('generic.submittedDate')}
                            </th>
                            <th />
                        </tr>
                    </thead>

                    <tbody>
                        {compositions?.map(composition => (
                            <SongRow
                                key={composition.id}
                                song={composition}
                                isDraft={isDraft}
                                showPubSongId={showPubSongId}
                                onClickSong={onClickSong}
                                onEdit={onEditSong}
                                onDelete={onDeleteSong}
                                onToggleDropdown={onToggleSongRowDropDown}
                            />
                        ))}
                    </tbody>
                </Table>
            )}

            {!isLoading && totalPages > 1 && (
                <div className="pagination-container">
                    <Pagination
                        onChange={onPaginationChange}
                        currentPage={page}
                        totalCount={totalCount || 0}
                        pageSize={pageSize}
                        onSetPageSize={onSetPageSize}
                    />
                </div>
            )}
        </Card>
    );
};

export default SongTable;
